import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  computed,
  inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  AuthService,
  EventService,
  NavigationService,
  SignInModalComponent,
} from '@jfw-library/ecommerce/core';
import { AfterSignIn } from 'common-types';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { modalSettings } from '../../../settings/modal-settings/modal-settings';
import { GetStartedModalComponent } from '../../../standalone/components/modals/get-started/get-started.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() alphaMode = false;

  @ViewChild(MatMenuTrigger) ocMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) hiwMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) inspirationMenuTrigger!: MatMenuTrigger;

  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  isLoggedIn = toSignal(this.authService.isLoggedIn$);
  user = toSignal(this.authService.user$);
  firstLetter = computed(() => {
    const user = this.user();
    if (!user) return '';
    return user?.displayName ? user.displayName[0].toUpperCase() : '';
  });

  subscription = new Subscription();
  showMobileSearch = false;
  servedEnvironment = environment.envVersion;
  showPanic =
    environment.envVersion === 'dev' || environment.envVersion === 'local';
  disablePanic = false;
  eventDocListenerActive = false;
  numEventDocUpdates$ = this.eventService.numEventDocUpdatesReceived$;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private eventService: EventService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.navigationService.showMobileSearch.subscribe((value) => {
        this.showMobileSearch = value;
      }),
    );

    // open get started modal with completed event
    // after user creates account/signs in
    this.subscription.add(
      this.authService.afterSignIn$.subscribe({
        next: (afterSignIn) => {
          if (afterSignIn === AfterSignIn.CreateGetStartedEvent) {
            this.onGetStarted();
          }
        },
      }),
    );

    this.subscription.add(
      this.eventService.eventDocListenerPanic$.subscribe(
        (value) => (this.disablePanic = value),
      ),
    );

    this.subscription.add(
      this.eventService.eventDocListenerActive$.subscribe((value) => {
        this.eventDocListenerActive = value;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public panicEventDocListener(): void {
    console.log('PANIC -- Removing Event Doc Listener...');
    this.eventService.panicEventDocListener();
  }

  onGetStarted(): void {
    this.dialog.open(GetStartedModalComponent, {
      autoFocus: false,
      disableClose: true,
      ...modalSettings,
    });
  }

  onOpenSignIn(): void {
    this.dialog.open(SignInModalComponent, {
      autoFocus: false,
      ...modalSettings,
    });
  }

  navigateToEvent(): void {
    this.eventService.routeToFirstStep();
  }

  onSidenav(): void {
    console.log('onSidenav');
    this.navigationService.showHeaderNav.next(true);
  }

  onSearch(): void {
    this.navigationService.showMobileSearch.next(!this.showMobileSearch);
  }
}
