import { Location } from '@angular/common';
import { Inject, Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ECOM_DEALER_USER_EVENT_API_SERVICES,
  ECOM_EVENT_API_SERVICES,
  ECOM_USER_EVENT_API_SERVICES,
} from '@jfw-library/ecommerce/api-services';
import {
  AuthService,
  CartService,
  EventService,
} from '@jfw-library/ecommerce/core';
import {
  EcomCommunicationQueueService,
  ValidationService,
} from '@jfw-library/ecommerce/shared';
import {
  convertDateToUnixTimestamp,
  getEventSteps,
  getOrganizerFromEvent,
} from 'business-logic';
import {
  DealerPortalEnvironment,
  DealerUserEvent,
  EcommerceMainEnvironment,
  Event,
  EventAdmin,
  EventMember,
  EventStep,
  InStoreInfo,
  MessageType,
  ShareEventDetails,
  UserEvent,
  Validator,
  EcomEventV6Types as v6,
} from 'common-types';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  filter,
  finalize,
  firstValueFrom,
  from,
  map,
  merge,
  of,
  share,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { toSignal } from '@angular/core/rxjs-interop';

type AssignLookUpdate = {
  memberId: string;
  lookId?: string | undefined;
};
@Injectable({
  providedIn: 'root',
})
export class EventManagerService implements OnDestroy, OnInit {
  private subscription = new Subscription();

  private authService = inject(AuthService);
  private user = toSignal(this.authService.user$);

  // private auth: Auth = inject(Auth);
  private ecomUserEventApiService = inject(ECOM_USER_EVENT_API_SERVICES.v4);
  private ecomDealerUserEventApiService = inject(
    ECOM_DEALER_USER_EVENT_API_SERVICES.v3,
  );
  private ecomEventApiService = inject(ECOM_EVENT_API_SERVICES.v8);
  private communicationQueueService = inject(EcomCommunicationQueueService);
  private eventService = inject(EventService);
  private cartService = inject(CartService);

  // EXT-2383
  private assignLooksToUpdate = new BehaviorSubject<AssignLookUpdate[]>([]);
  public assignLooksToUpdate$ = this.assignLooksToUpdate.asObservable();

  // EXT-2893
  private shareEventDetailsFormValid = new BehaviorSubject<boolean>(true);
  public readonly shareEventDetailsFormValid$ =
    this.shareEventDetailsFormValid.asObservable();

  private displayThankYou = new BehaviorSubject<boolean>(false);
  public displayThankYou$ = this.displayThankYou.asObservable();

  /** When a click happens on next, previous, or EventStepper, the requestedStep is passed into this subject.
   * The requestedStep is determined by the function nextClicked, previousClicked, or stepRequested, respectively,
   * and is based on the current step, which itself is based on the active route.
   * Nexting this subject will trigger the results$ observable to run.
   */
  private readonly requestedStep = new BehaviorSubject<EventStep | undefined>(
    undefined,
  );
  private readonly requestedStep$ = this.requestedStep.asObservable().pipe(
    tap((requestedStep) => {
      console.log('requestedStep:', requestedStep);
    }),
  );
  private readonly isSavingSubject = new BehaviorSubject<boolean>(false);
  public readonly isSaving$ = this.isSavingSubject.asObservable();
  private clickDirectionSubject = new Subject<'next' | 'prev'>();
  /** Used to show progress spinner on the next or previous button during save.
   * This is useful for showing which direction the navigation is going (forward or back) during a save,
   * especially when the "click" comes from the stepper component.
   */
  public readonly clickDirection$ = this.clickDirectionSubject.asObservable();
  private eventUpdatedSubject = new Subject<Event>();
  public readonly eventUpdated$ = this.eventUpdatedSubject.asObservable();

  public currentChildRoute$: Observable<string> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => {
      const url = this.router.url;
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1];
    }),
    tap((route) => console.log('currentChildRoute:', route)),
  );

  constructor(
    @Inject('environment')
    private environment: DealerPortalEnvironment | EcommerceMainEnvironment,
    private validationService: ValidationService,
    private router: Router,
    private location: Location,
  ) {
    console.log(
      'EventManagerService is using Event API version: ',
      this.ecomEventApiService.apiUrl,
    );
  }

  ngOnInit(): void {
    // console.warn('NG ON INIT FIRE');
    // this.setEventAndSteps();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // ************************************************************************************************
  // *                           Event Steps Save, Validate, Navigate                               *
  // ************************************************************************************************

  // EXT-2383
  private findFirstInvalidIndex(event: Event, eventSteps: EventStep[]): number {
    for (let i = 0; i < eventSteps.length; i++) {
      const stepIsValid = this.validationService.isEventStepValid(
        event,
        eventSteps[i].route,
        eventSteps,
      ).valid;
      if (!stepIsValid) {
        return i;
      }
    }
    return -1;
  }

  private getStepIndex(
    requestedStep: EventStep | undefined,
    eventSteps: EventStep[],
  ): number {
    return eventSteps.findIndex((step) => step.route === requestedStep?.route);
  }

  private getCurrentStepFromRoute(
    eventSteps: EventStep[],
    parentRoute: string,
    activeRoute: string,
  ): EventStep | undefined {
    const selectedStep = eventSteps.find((step) => {
      if (`${parentRoute}/${step.route}` === activeRoute) {
        return true;
      }
      return false;
    });

    return selectedStep;
  }

  private getParentRoute(eventId: string): string {
    return `/event/${eventId}`;
  }

  private getActiveRoute(): string {
    return this.router.url;
  }

  public setAssignLooksToUpdate(assignLooksToUpdate: AssignLookUpdate[]) {
    this.assignLooksToUpdate.next(assignLooksToUpdate);
  }

  public setShareEventDetailsFormState(isFormValid: boolean) {
    this.shareEventDetailsFormValid.next(isFormValid);
  }

  /** This is used by the EventManagerComponent to notify when the "Next" button is clicked. */
  public nextClicked() {
    console.log('nextClicked');
    // Find the next step based on current step
    // Logic here to find next step from currentStep
    const event = this.eventService.selectedEvent;
    if (!event) {
      console.error(
        'selectedEvent is null.  Unable to determine previous step.',
      );
      throw Error('selectedEvent is null.  Unable to determine previous step.');
    }
    const eventId = event.id;

    const activeRoute = this.getActiveRoute();
    const parentRoute = this.getParentRoute(eventId);
    const eventSteps = getEventSteps(
      event,
      this.environment.dealerPortal,
      this.user(),
    );

    const nextStep = this.getNextStep(eventSteps, activeRoute, parentRoute);
    this.clickDirectionSubject.next('next');
    this.requestedStep.next(nextStep);
  }

  private getNextStep(
    eventSteps: EventStep[],
    activeRoute: string,
    parentRoute: string,
  ): EventStep | undefined {
    const selectedIndex = eventSteps.findIndex(
      (step) => `${parentRoute}/${step.route}` === activeRoute,
    );
    if (
      eventSteps.length > selectedIndex &&
      eventSteps[selectedIndex + 1] !== undefined
    ) {
      return eventSteps[selectedIndex + 1];
    }
    return;
  }

  /** This is used by the EventManagerComponent to notify when the "Previous" button is clicked. */
  public previousClicked() {
    console.log('previousClicked');
    const event = this.eventService.selectedEvent;
    if (!event) {
      console.error(
        'selectedEvent is null.  Unable to determine previous step.',
      );
      throw Error('selectedEvent is null.  Unable to determine previous step.');
    }
    const eventId = event.id;

    const activeRoute = this.getActiveRoute();
    const parentRoute = this.getParentRoute(eventId);
    const eventSteps = getEventSteps(
      event,
      this.environment.dealerPortal,
      this.user(),
    );
    const currentRoute = this.router.url.split('/');
    const onTrackProgress =
      currentRoute[currentRoute.length - 1] === 'track-progress';
    const currentStep = this.getCurrentStepFromRoute(
      eventSteps,
      parentRoute,
      activeRoute,
    );
    const onEventDetails = currentRoute[currentRoute.length - 1] === 'details';
    const onReviewMeasurements =
      currentRoute[currentRoute.length - 1] === 'review-measurements';

    /**
     * This is using a router navigate because widget creates an iframe which overrides location.back()
     */
    if (onReviewMeasurements) {
      this.router.navigate([
        '/event/' + this.eventService?.selectedEvent?.id + '/track-progress',
      ]);
    }

    if (onTrackProgress || onEventDetails) {
      this.location.back();
    }

    const previousStep = this.getPreviousStep(
      eventSteps,
      eventId,
      activeRoute,
      parentRoute,
    );

    if (currentStep !== undefined) {
      this.clickDirectionSubject.next('prev');
      this.requestedStep.next(previousStep);
      return;
    }
  }

  private getPreviousStep(
    eventSteps: EventStep[],
    eventId: string,
    activeRoute: string,
    parentRoute: string,
  ): EventStep | undefined {
    const selectedIndex = eventSteps.findIndex(
      (step) => `${parentRoute}/${step.route}` === activeRoute,
    );
    if (
      eventSteps.length > selectedIndex &&
      eventSteps[selectedIndex - 1] !== undefined
    ) {
      return eventSteps[selectedIndex - 1];
    }
    return;
  }

  /** This is used by EventStepper to notify when a step is clicked.*/
  public stepRequested(eventStep: EventStep | undefined) {
    const event = this.eventService.selectedEvent;
    if (!event) {
      console.error(
        'selectedEvent is null.  Unable to determine previous step.',
      );
      throw Error('selectedEvent is null.  Unable to determine previous step.');
    }
    const eventId = event.id;

    const activeRoute = this.getActiveRoute();
    const parentRoute = this.getParentRoute(eventId);
    const eventSteps = getEventSteps(
      event,
      this.environment.dealerPortal,
      this.user(),
    );

    const currentStep = this.getCurrentStepFromRoute(
      eventSteps,
      parentRoute,
      activeRoute,
    );
    const requestedStepIndex = this.getStepIndex(eventStep, eventSteps);
    const currentStepIndex = this.getStepIndex(currentStep, eventSteps);
    const nextOrPrev = requestedStepIndex >= currentStepIndex ? 'next' : 'prev';
    if (eventStep) {
      this.clickDirectionSubject.next(nextOrPrev);
      this.requestedStep.next(eventStep);
    }
  }

  private prepareCheckout() {
    this.cartService.resetCheckoutState();
    this.cartService.checkoutCartSubject.next(undefined);
    this.eventService.canProceed$.next(true);
    return;
  }

  /**
   * EXT-2383
   * This is the main observable that is used to save changes, validate the event, and navigate to the requested step if the current step is valid.
   * If the current step is invalid, then the validator is returned so that it can be displayed in the component.
   *  */
  private navigationValidationResult$ = this.requestedStep$.pipe(
    // skip(1), // skip the first value because it is undefined
    withLatestFrom(this.isSaving$, this.assignLooksToUpdate),
    filter(([requestedStep, isSaving, event]) => {
      // don't do anything if we are already saving (UI should prevent this, but just in case)
      const proceed = !isSaving && requestedStep !== undefined;
      console.log(
        `${
          proceed ? 'allowing' : 'stopping'
        } click in navigationValidationResult$`,
      );
      return proceed;
    }),
    tap(() => {
      // Initial step is to disable saving also subscribed to in each event step
      this.isSavingSubject.next(true);
    }),
    /// ******** STEP 1 - Handle any saves that need to be made (currently this is only for assignLooks). ******** ///
    /// We are saving assignLook changes even if it results in an invalid step. This way the user's selections are always saved.
    switchMap(([requestedStep, isSaving, assignLooksQueue]) => {
      // Check to make sure the selectedEvent is present before proceeding the checks.
      const eventBeforeSave = this.eventService.selectedEvent;
      if (eventBeforeSave == null) {
        // throw new Error('no event');
        return of({ event: null, requestedStep });
      }
      // This is set in assign-looks, if there are updates to be made we will need to save
      // OR just output the switchmap the same event/requested eventStep
      if (assignLooksQueue.length > 0) {
        return from(
          this.assignLooks(eventBeforeSave.id, assignLooksQueue),
        ).pipe(
          tap(() => {
            this.assignLooksToUpdate.next([]);
          }),
          map((result) => {
            // Update the selectedEvent in EventService with the updated event
            return this.eventService.setSelectedEventWithEvent(
              result.updatedEvent,
              'EventManagerService - navigationValidationResult$',
            );
          }),
          map((updatedEvent) => ({
            // pass on the updated event and requestedStep to the next step in the pipe
            event: updatedEvent,
            requestedStep,
          })),
          catchError((error) => {
            console.error('Error assigning looks', error);
            alert(
              'There was an error saving your look selections.  Please try again or contact Customer Service.',
            );
            this.assignLooksToUpdate.next([]);
            return of({ event: null, requestedStep }); // returning null event so that the next step will return an error validator
          }),
        );
      }

      return of({ event: eventBeforeSave, requestedStep });
    }),

    /// ******** STEP 2 - Check if the step is valid, if it is navigate to the requested step. ******** ///
    switchMap(({ event, requestedStep }) => {
      console.log('Checking if current step is valid...');

      /* If we get an error when saving the assignLooksQueue, the previous step will return event as null.
      In this case, we will return an error validator so that the component will reset itself.
      We pass a valid=true value so that the next/prev buttons are enabled. */
      if (!event) {
        const errorValidator: Validator = {
          valid: true,
          msg: 'Error Saving',
          isError: true,
        };
        return of(errorValidator);
        // return of(undefined);
      }
      /**
       * Check to make sure the currentRoute (based on the activeRoute router url)
       *  If step is valid route to next step
       *  else return a "validator" object which is used in each component to display errors
       *  */
      const parentRoute = this.getParentRoute(event.id);
      const activeRoute = this.getActiveRoute();
      const eventSteps = getEventSteps(
        event,
        this.environment.dealerPortal,
        this.user(),
      );
      const currentStep = this.getCurrentStepFromRoute(
        eventSteps,
        parentRoute,
        activeRoute,
      );

      const requestedStepIndex = this.getStepIndex(requestedStep, eventSteps);
      const currentStepIndex = this.getStepIndex(currentStep, eventSteps);

      console.log({
        requestedStep,
        currentStep,
        requestedStepIndex,
        activeRoute,
        eventSteps,
      });

      // This will return the default invalid validator if currentStep is undefined.
      const validator = this.validationService.isEventStepValid(
        event,
        currentStep?.route,
        eventSteps,
      );

      // If the requested step is before the current step, then we ignore validation and navigate to the requested step
      if (requestedStepIndex <= currentStepIndex && requestedStepIndex !== -1) {
        console.log(
          `Navigating to requested step '${requestedStep?.route}' (without validation) because it is before the current step.`,
        );
        return from(
          this.router.navigate([
            `/${parentRoute}/${eventSteps[requestedStepIndex].route}`,
          ]),
        ).pipe(
          map(() => {
            return undefined;
          }),
        );
      }

      if (
        currentStep?.route !== 'confirmation' &&
        event.inStoreInfo?.dateSharedWithStore !== undefined
      ) {
        // console.log(
        //   '!! TRIED TO GO TO IN-STORE EVENT AFTER SUBMISSION (event-manager-service) !!',
        // );

        this.router
          .navigate(['/event/' + event.id + '/confirmation'])
          .then(() => {
            return;
          });
      }

      if (validator.valid) {
        console.log(`Current step '${currentStep?.route}' is valid`);

        // console.log('currentStep.route: ' + currentStep?.route);
        // console.log('requestedStep.route: ' + requestedStep?.route);

        // If we are here, then we are not on the review-event step, but we are on a valid step.
        if (requestedStep === undefined) {
          console.error(
            'Current step is valid, but requested step is undefined.  This is likely an error.',
          );
          throw Error(
            'Current step is valid, but requested step is undefined.  This is likely an error.',
          );
        }

        const firstInvalidIndex = this.findFirstInvalidIndex(event, eventSteps); // if there are no invalid steps, this will return -1
        console.log({ firstInvalidIndex, requestedStepIndex });
        // **** HAPPY PATH - If the requested step is before the first invalid step (or there are no invalid steps), then we can navigate to the requested step.
        // This ensures that we don't skip any invalid steps.
        if (
          firstInvalidIndex === -1 ||
          requestedStepIndex <= firstInvalidIndex
        ) {
          /// All steps are valid, so we can send notifications and navigate to the requested step.
          return this.sendNotificationsOrPrepareCheckout(
            currentStep,
            requestedStep,
            event,
          ).pipe(
            tap(() => {
              console.log(
                `Navigating to requested step '${requestedStep?.route}' because current step is valid and either requested step is before the first invalid step or there are no invalid steps.`,
              );
            }),
            switchMap(() => {
              const url =
                requestedStep.route === '/checkout'
                  ? '/checkout'
                  : `/${parentRoute}/${requestedStep.route}`;
              return from(this.router.navigate([url]));
            }),
            tap(() => {
              this.isSavingSubject.next(false);
            }),
            map(() => {
              return undefined;
            }),
          );
        }

        // If the requested step is after the first invalid step, then we need to navigate to the first invalid step.
        const firstInvalidStep = eventSteps[firstInvalidIndex];

        console.log(
          `Navigating to first invalid step '${firstInvalidStep.route}' because requested step is after the first invalid step.`,
        );
        return from(
          this.router.navigate([`/${parentRoute}/${firstInvalidStep.route}`]),
        ).pipe(
          tap(() => {
            this.isSavingSubject.next(false);
          }),
          map(() => {
            return undefined;
          }),
        );
      }

      // if (requestedStep?.route === 'review-event') {
      //   console.log('Current step is invalid, but requested step is review-event.  Proceeding to review-event...');
      //   return from(
      //     this.router.navigate([
      //       `/${parentRoute}/review-event`,
      //     ])
      //   ).pipe(
      //     tap(() => {
      //       this.isSavingSubject.next(false);
      //     }),
      //     map(() => {
      //       return validator;
      //     })
      //   );
      // }

      console.log(
        `Current step '${currentStep?.route}' is invalid.  Returning validator...`,
        validator,
      );

      // If we are here, then the step is not valid, so we return the validator result.
      return of(validator);
    }),
    tap((result) => {
      this.isSavingSubject.next(false);
    }),
    // share is necessary to make sure everything above is only called once even if multiple subscribers.
    // NOT using shareReplay because components should only get a validation message AFTER a click.  Newly subscribed components should not get the last value.
    share(),
    catchError((error) => {
      console.error('Error navigating to requested step:', error);
      console.log(error.error);
      this.isSavingSubject.next(false);
      const errorValidator: Validator = {
        valid: true, // similar to above, needs to be true so that the next/prev buttons can be enabled (component will reset itself if isError is true)
        msg: 'Error navigating to requested step.  Please try again or contact Customer Service.',
        isError: true,
      };
      return of(errorValidator);
      // return throwError(() => error);
    }),
    /// Finalize is used to reset the isSaving state because the tap above was not being called after navigation.
    finalize(() => {
      console.log('Finalizing navigationValidationResult$');
      this.requestedStep.next(undefined);
      this.isSavingSubject.next(false);
    }),
  );

  private updateEventValidationResult$ = this.eventUpdatedSubject.pipe(
    switchMap((event) => {
      const parentRoute = this.getParentRoute(event.id);
      const eventSteps = getEventSteps(
        event,
        this.environment.dealerPortal,
        this.user(),
      );
      const activeRoute = this.getActiveRoute();
      const currentStep = this.getCurrentStepFromRoute(
        eventSteps,
        parentRoute,
        activeRoute,
      );
      const validator = this.validationService.isEventStepValid(
        event,
        currentStep?.route,
        eventSteps,
      );
      return of(validator);
    }),
    share(),
  );

  public readonly results$ = merge(
    this.navigationValidationResult$,
    this.updateEventValidationResult$,
  ).pipe(
    tap((result) => console.log('Validation results$:', result)),
    tap((result) =>
      this.eventService.canProceed$.next(result?.valid !== false),
    ),
    share(),
  );

  /** Returns true when all of the following are true:
   * 1. The event is not saving
   * 2. There are no assignLooks updates in the queue
   * 3. The current step is invalid
   */
  public readonly showWarning$ = combineLatest([
    this.isSaving$,
    this.assignLooksToUpdate$,
    this.results$,
  ]).pipe(
    map(([isSaving, assignLooksQueue, results]) => {
      return (
        results?.valid === false && !isSaving && assignLooksQueue.length === 0
      );
    }),
  );

  public async getUserEventsForCurrentUserByEventId(
    eventId: string,
  ): Promise<UserEvent[]> {
    return firstValueFrom(
      this.ecomUserEventApiService.getUserEventForCurrentUserByEventId(eventId),
    );
  }

  private sendNotificationsOrPrepareCheckout(
    currentStep: EventStep | undefined,
    requestedStep: EventStep | undefined,
    event: Event,
  ) {
    console.log(
      'Checking if we need to send notifications or prepare checkout...',
    );
    console.log(`Current step is ${currentStep?.route}`);

    const isFinalStepBeforeShareWithRetailer =
      currentStep?.route === 'share-event' &&
      requestedStep?.route === 'confirmation';
    if (isFinalStepBeforeShareWithRetailer) {
      console.log('Will share with retailer...');
      return from(this.shareWithRetailer(event));
    }

    const isDealerPortal = this.environment.dealerPortal;

    // The next button is "Transfer Event" on the review-event step in Dealer Portal, so we need to send the invite, then navigate to the transfer-confirmation page.
    const isFinalStepBeforeShareWithOrganizer =
      isDealerPortal &&
      currentStep?.route === 'review-event' &&
      requestedStep?.route === 'transfer-confirmation';
    if (isFinalStepBeforeShareWithOrganizer) {
      console.log('Will send invite to organizer...');
      return from(this.sendInviteToOrganizer(event));
    }

    const isOnlineEvent = event?.inStoreInfo === undefined;
    //   // If we are on the review-event step (or wearers-info), we need to prepare checkout.
    const isFinalStepBeforeCheckout =
      currentStep?.route === 'review-event' ||
      (currentStep?.route === 'wearers-info' && isOnlineEvent);

    const shouldPrepareCheckout =
      !isDealerPortal &&
      isFinalStepBeforeCheckout &&
      requestedStep?.route === '/checkout';
    console.log({
      isDealerPortal,
      requestedRoute: requestedStep?.route,
      shouldPrepareCheckout,
    });

    if (shouldPrepareCheckout) {
      console.log('Will prepare checkout...');
      return of(this.prepareCheckout());
    }

    /// If we get here, then we are not on the final step before share or checkout, so we can just return an empty observable.
    console.log('No notifications to send and not going to checkout.');
    return of(undefined);
  }

  // ************************************************************************************************
  // *                                         Event Updates                                        *
  // ************************************************************************************************

  public async setIsOrganizer(
    userEventDocId: string,
    isOrganizer: boolean,
  ): Promise<void> {
    const response = await firstValueFrom(
      this.ecomUserEventApiService.setIsOrganizer(userEventDocId, {
        isOrganizer,
      }),
    );
    return response;
  }
  // public async updateUserEvent(userEvent: UserEvent): Promise<void> {
  //   if (!userEvent.docId) throw new Error('UserEvent id is required');
  //   const response = await firstValueFrom(this.ecomUserEventApiService.updateUserEvent(userEvent.docId, userEvent));
  //   return response;
  // }

  public thisMemberIsMe(member: EventMember): boolean {
    return member.userId === this.user()?.uid;
  }

  public switchToOrderOnline(eventId: string) {
    console.log('switchToOrderOnline');
    return firstValueFrom(
      this.ecomEventApiService.switchToOrderOnline(eventId),
    );
  }

  public switchToOrderInStore(
    eventId: string,
    data: v6.SwitchToOrderInStoreData,
  ) {
    return firstValueFrom(
      this.ecomEventApiService.switchToOrderInStore(eventId, data),
    );
  }

  public switchToIndividual(eventId: string) {
    return firstValueFrom(this.ecomEventApiService.switchToIndividual(eventId));
  }

  public switchToGroup(eventId: string) {
    return firstValueFrom(this.ecomEventApiService.switchToGroup(eventId));
  }

  public updateShareEventDetails(
    eventId: string,
    // inStoreInfo: InStoreInfo | undefined,
    shareEventDetails: ShareEventDetails,
  ) {
    // if (inStoreInfo == undefined) {
    //   return;
    // }
    return firstValueFrom(
      this.ecomEventApiService.updateInStoreInfo(eventId, {
        shareEventDetails,
      }),
    );
  }

  public deleteAllUserEventsByEventId(
    eventId: string,
  ): Promise<{ numUserEventDocsDeleted: number }> {
    return firstValueFrom(
      this.ecomUserEventApiService.deleteAllUserEventsByEventId(eventId),
    );
  }

  // public deleteUserEventByMemberIdEventId(eventId: string, memberId: string,): Promise<{ numUserEventDocsDeleted: number }> {
  //   return firstValueFrom(this.ecomUserEventApiService.deleteUserEventByMemberIdEventId(eventId, memberId));
  // }

  /// ************ Event Members ************ ///

  /**
   * Adds a member to an event
   * @param eventId id of the event to add the member to
   * @param member member to add to the event
   * @returns
   */
  public addMember(
    eventId: string,
    member: EventMember,
  ): Observable<v6.AddMemberSuccessResponse> {
    const data: v6.AddMemberData = { member };
    return this.ecomEventApiService.addMember(eventId, data).pipe(
      tap((response) => {
        console.log('Member added successfully:', response);
        this.eventUpdatedSubject.next(response.updatedEvent);
      }),
    );
  }

  /**
   *
   * @param eventId id of the event
   * @param memberId id of the member to update
   * @param updatedDetails  Only the properties that are being updated need to be included in the object.
   * For example, if only the email is being updated, then the object would be { email: 'newEmail' }
   *
   * Allowed properties are: firstName, lastName, memberRole, phone, email
   * @returns
   */
  public updateMemberDetails(
    eventId: string,
    memberId: string,
    updatedDetails: v6.UpdateEventMemberDetailsData,
  ): Observable<v6.UpdateEventMemberDetailsSuccessResponse> {
    return this.ecomEventApiService
      .updateMemberDetails(eventId, memberId, updatedDetails)
      .pipe(
        tap((response) => {
          console.log('Member details updated successfully:', response);
          this.eventUpdatedSubject.next(response.updatedEvent);
        }),
      );
  }

  /**
   * Deletes a member from an event (and all associated UserEvents, unless used by another member or admin)
   * @param eventId id of the event
   * @param memberId id of the member to delete
   * @returns
   */
  deleteMember(
    eventId: string,
    memberId: string,
  ): Observable<v6.DeleteMemberSuccessResponse> {
    return this.ecomEventApiService.deleteMember(eventId, memberId).pipe(
      tap((response) => {
        console.log('Member deleted successfully:', response);
        this.eventUpdatedSubject.next(response.updatedEvent);
      }),
    );
  }

  // /**
  //  * Marks an invite as sent for a member of an event
  //  * @param eventId id of the event
  //  * @param memberId id of the member
  //  * @returns
  //  */
  // private async markInviteSent(eventId: string, memberId: string): Promise<v5.MarkInviteSentSuccessResponse> {
  //   const response = await firstValueFrom(this.ecomEventApiService.markInviteSent(eventId, memberId));
  //   return response;
  // }

  public async sendMemberInvite(
    event: Event,
    member: EventMember,
  ): Promise<void> {
    console.log('sending invite to:', member);
    if (member?.email !== undefined) {
      try {
        const inviteResponse =
          await this.communicationQueueService.queueEmailMemberInvite(
            event,
            member.email,
            member.firstName,
            member.lastName,
          );
      } catch (error) {
        console.error('Error sending invite', error);
        throw error;
      }

      console.log('Invite sent successfully. Now marking invite sent...');

      try {
        if (member?.id === undefined) {
          console.error('member id is undefined.  Unable to mark invite sent.');
          throw new Error(
            'member id is undefined.  Unable to mark invite sent.',
          );
        }
        const { updatedEvent } = await firstValueFrom(
          this.ecomEventApiService.markInviteSent(event.id, member.id),
        );
        console.log('Invite sent marked successfully.');
        console.log('setting selected event with updatedEvent...');
        this.eventService.setSelectedEventWithEvent(
          updatedEvent,
          'EventManagerService - sendMemberInvite',
        );
        return;
      } catch (error) {
        console.error('Error marking invite sent', error);
        // not throwing an error here because the invite was sent successfully.
      }
    }
    throw new Error('Member email is undefined.  Unable to send invite.');
  }

  async sendInviteToOrganizer(event: Event): Promise<void> {
    const organizer = getOrganizerFromEvent(event);
    if (!organizer) {
      throw new Error('Organizer not found');
    }
    try {
      const response =
        await this.communicationQueueService.queueTransferEventEmail(
          event,
          organizer.email,
          organizer.name,
        );
      if (response === undefined) {
        console.warn(
          'Event transfer email was NOT queued successfully, likely because no stores were found for the dealerId in the event.',
        );
        throw new Error(
          'Unable to send transfer email.  No stores found for this dealerId.',
        );
      }
    } catch (error) {
      this.eventService.transferEventError$.next(true);
      throw error;
    }

    console.log('Event transfer email queued successfully.');
    try {
      await this.markEventAsTransferred(event.id);
      console.log('Event marked as transferred successfully.');
      return;
    } catch (error) {
      console.error(
        'Transfer Event email was successfully queued, but there was an error marking event as transferred',
        error,
      );
      // not throwing an error here because the email was sent successfully.
      return;
    }
  }

  /** Sends notifications to Dealer (email) and consumer (email and sms), and if successful,
   * updates the event with the dateSharedWithStore.
   */
  async shareWithRetailer(event: Event): Promise<void> {
    if (event.inStoreInfo == undefined) {
      console.error(
        'InStoreInfo is undefined.  Unable to share with retailer.',
      );
      return;
    }
    if (event.inStoreInfo?.dateSharedWithStore !== undefined) {
      console.warn(
        'Event has already been shared with retailer.  Not sending notifications.',
      );
      alert('Event has already been shared with retailer.');
      return;
    }

    const numEventMembers = event.members.length;
    const eventSize =
      numEventMembers > 0
        ? numEventMembers
        : (event.inStoreInfo.shareEventDetails?.eventSize ?? 0);
    const dateSharedWithStore = convertDateToUnixTimestamp(
      new Date().toString(),
    );
    const updatedInStoreInfo: InStoreInfo = {
      ...event.inStoreInfo,
      dateSharedWithStore,
      shareEventDetails: {
        ...event.inStoreInfo.shareEventDetails,
        eventSize,
      },
    };
    const eventToShare = {
      ...event,
      inStoreInfo: updatedInStoreInfo,
    };

    /// Send in-store share emails
    try {
      console.log('Sending in-store share emails...');
      const response =
        await this.communicationQueueService.queueInStoreShare(eventToShare);
      if (response === undefined) {
        console.warn('In-store share emails were NOT all queued successfully.');
        throw new Error(
          'Unable to send in-store share email.  No stores found for this dealerId.',
        );
      }
      const allSuccess = response.every((res) => res.status === 'fulfilled');
      const dealerSuccess = response.some(
        (res) =>
          res.status === 'fulfilled' &&
          res.value.messageType === MessageType.in_store_share_dealer,
      );
      response.forEach((res) => {
        if (res.status === 'rejected') {
          console.error('Failed to queue notification: ', res.reason);
        } else {
          console.log('Notification queued successfully: ', res.value);
        }
      });

      if (!allSuccess) {
        if (!dealerSuccess) {
          throw new Error('Failed to share with dealer');
        }
      } else {
        console.log('All notifications queued successfully.');
      }
    } catch (error) {
      console.error('Error sending in-store share emails', error);
      this.eventService.transferEventError$.next(true);
      throw error;
    }

    /// Update the event doc
    console.log('Updating InStoreInfo on Event doc...');
    try {
      const result = await firstValueFrom(
        this.ecomEventApiService.updateInStoreInfo(
          event.id,
          updatedInStoreInfo,
        ),
      );
      this.displayThankYou.next(true);
      console.log('InStoreInfo updated successfully:', result);
    } catch (error) {
      console.error('Error updating InStoreInfo', error);
      throw error;
    }
    return;
  }

  /// ************ Admins ************ ///
  /**
   * Adds a admin to an event
   * @param eventId id of the event to add the member to
   * @param admin admin to add to the event
   * @returns
   */
  public addAdminToEvent(eventId: string, admin: EventAdmin) {
    const data: v6.AddAdminData = { admin };
    return firstValueFrom(this.ecomEventApiService.addAdmin(eventId, data));
  }

  public updateOrganizer(eventId: string, adminId: string, admin: EventAdmin) {
    return firstValueFrom(
      this.ecomEventApiService.updateAdminDetails(eventId, adminId, admin),
    );
  }

  public async updateEventDetails(
    eventId: string,
    eventDetails: v6.UpdateEventDetailsData,
  ) {
    return firstValueFrom(
      this.ecomEventApiService.updateEventDetails(eventId, eventDetails),
    );
  }

  public markEventAsTransferred(eventId: string): Promise<DealerUserEvent> {
    const user = this.user();
    if (!user) {
      throw new Error('User not authenticated');
    }

    return firstValueFrom(
      this.ecomDealerUserEventApiService.markEventAsTransferred(eventId, user),
    );
  }

  /// ************ Looks ************ ///
  public selectLook(eventId: string, selectedLookId: string) {
    return firstValueFrom(
      this.ecomEventApiService.selectLook(eventId, selectedLookId).pipe(
        tap((result) => {
          console.log('Look selected successfully:', result);
          this.eventUpdatedSubject.next(result.updatedEvent);
        }),
      ),
    );
  }

  public copyLook(eventId: string, lookId: string) {
    return firstValueFrom(
      this.ecomEventApiService.copyLook(eventId, lookId).pipe(
        tap((result) => {
          console.log('Look copied successfully:', result);
          this.eventUpdatedSubject.next(result.updatedEvent);
        }),
      ),
    );
  }

  public deleteLook(eventId: string, lookId: string) {
    return firstValueFrom(
      this.ecomEventApiService.deleteLook(eventId, lookId).pipe(
        tap((result) => {
          console.log('Look deleted successfully:', result);
          this.eventUpdatedSubject.next(result.updatedEvent);
        }),
      ),
    );
  }

  public assignLooks(
    eventId: string,
    data: Parameters<typeof this.ecomEventApiService.assignLooks>[1],
  ) {
    return firstValueFrom(this.ecomEventApiService.assignLooks(eventId, data));
  }
}
