import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { EnsembleService } from '@jfw-library/ecommerce/core';
import { setColorClass } from 'business-logic';
import { FilterCategory } from 'common-types';

@Component({
  selector: 'app-ensemble-filters',
  templateUrl: './ensemble-filters.component.html',
  styleUrls: ['./ensemble-filters.component.scss'],
})
export class EnsembleFiltersComponent {
  filterCategories = toSignal(this.ensembleService.filterCategories);
  // @Input() filterCategories: FilterCategory[] | undefined;
  // @Output() filterSelectedOutput = new EventEmitter<any>();
  colorMap: Record<string, string> = {};

  constructor(private ensembleService: EnsembleService) {}

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  rentOrBuyMask(option: string): string {
    let maskedOption = option;
    switch (option) {
      case 'Rental':
        maskedOption = 'Rent';
        break;
      case 'Purchase':
        maskedOption = 'Buy';
        break;
      case 'Rental/Purchase':
        maskedOption = 'Rent/Buy';
        break;
    }
    return maskedOption;
  }

  public optionClicked(
    category: FilterCategory,
    categoryIndex: number,
    optionIndex: number,
  ): void {
    // Single Selector logic
    // if (category.select === FilterSelect.Single) {
    //   this.filterCategories![categoryIndex].options?.forEach(
    //     (option, index) => {
    //       if (index !== optionIndex) {
    //         option.selected = false;
    //       }
    //     }
    //   );
    // }
    console.log('filter > optionsClicked()');
    // this.filterSelectedOutput.emit(category);
    this.ensembleService.triggerEnsembleSearch.next({ triggerSearch: true });
    //this.ensembleService.searchEnsembles.next();
  }
}
