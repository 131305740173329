import { makeStateKey } from '@angular/core';
import { CategoryItem, EcomStyle } from 'common-types';

export type SearchResultsForStylesPlp = {
  styles: EcomStyle[];
  resultsCount: number;
  categoryItem: CategoryItem;
};

export const SEARCH_RESULTS_FOR_STYLES_PLP_KEY =
  makeStateKey<SearchResultsForStylesPlp>('SEARCH_RESULTS_FOR_STYLES_PLP_KEY');
