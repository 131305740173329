import { InjectionToken } from '@angular/core';

/** This value is used to track the series of api requests that are made from a specific SSR function request.
 * It is set on the server and sent as a header ('ssr-request-received-time') on all requests made from the server.
 * It is passed through TransferState to the browser,
 * and then used to set the header on all requests made from the browser (via the ssrRequestReceivedTimeInterceptor).
 */
export const SSR_REQUEST_RECEIVED_TIME_TOKEN = new InjectionToken<string>(
  'SSR_REQUEST_RECEIVED_TIME_TOKEN',
);
