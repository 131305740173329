import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { UserEvent, EcomUserEventV4Types as v4 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomUserEvent_V4_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = '/api/4/userEvent';

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       CREATE                       *
   ******************************************************/

  /*******************************************************
   *                      READ                           *
   *******************************************************/
  /**
   * Gets all UserEvent docs for the current user that have an id property that matches the eventId
   * The current user is passed via the auth token in the request headers.
   * @param eventId the id of the event
   * @returns an array of UserEvent docs for the current user
   */
  public getUserEventForCurrentUserByEventId(
    eventId: string,
  ): Observable<UserEvent[]> {
    const apiUrl = `${this.apiUrl}/events/${eventId}/currentUser`;

    return this.httpClient.get<v4.GetUserEventsForCurrentUserByEventIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  /**
   * Gets all UserEvent docs for the current user.
   * The current user is passed via the auth token in the request headers
   * @returns an array of UserEvent docs for the current user
   */
  public getUserEventsForCurrentUser(): Observable<UserEvent[]> {
    const apiUrl = `${this.apiUrl}/currentUser`;

    return this.httpClient.get<v4.GetUserEventsForCurrentUserSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
    // .pipe(
    //   map((events) =>
    //     events.sort(
    //       (a, b) =>
    //         new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
    //     )
    //   ),
    //   share()
    // );
  }

  /********************************************************
   *                      UPDATE                          *
   * ******************************************************/
  public setIsOrganizer(docId: string, data: v4.SetIsOrganizerEventData) {
    const apiUrl = `${this.apiUrl}/${docId}/setIsOrganizer`;

    return this.httpClient.put<v4.UpdateUserEventSuccessResponse>(
      apiUrl,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }
  // Re-factored to just setIsOrganizer (see above)
  // public updateUserEvent(docId: string, userEvent: UserEvent) {
  //   const apiUrl = `${this.apiUrl}/${docId}/update`;

  //   const data: v4.UpdateUserEventData = { ...userEvent }

  //   return this.httpClient.put<v4.UpdateUserEventSuccessResponse>(
  //     apiUrl,
  //     data,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     }
  //   );
  // }

  /********************************************************
   *                     DELETE                           *
   ********************************************************/

  public deleteAllUserEventsByEventId(
    eventId: string,
  ): Observable<v4.DeleteAllUserEventsByEventIdSuccessResponse> {
    const apiUrl = `${this.apiUrl}/events/${eventId}/deleteAllUserEventsByEventId`;

    return this.httpClient.delete<v4.DeleteAllUserEventsByEventIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  // public deleteUserEventByMemberIdEventId(
  //   eventId: string,
  //   memberId: string,
  // ): Observable<v4.DeleteAllUserEventsByEventIdSuccessResponse> {
  //   const apiUrl = `${this.apiUrl}/events/${eventId}/members/${memberId}/deleteByMemberIdEventId`;

  //   return this.httpClient.delete<v4.DeleteUserEventByMemberIdEventIdSuccessResponse>(
  //     apiUrl,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     }
  //   );

  // }
}
