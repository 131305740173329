import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import { appCheckInterceptor } from '@jfw-library/shared/app-check';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { ssrRequestReceivedTimeInterceptor } from './core/interceptors/ssr-request-received-time/ssr-request-received-time.interceptor';
import { TokenInterceptor } from './core/interceptors/token/token.interceptor';
import { TransferStateService } from './core/services/transfer-state/transfer-state.service';
import { SSR_REQUEST_RECEIVED_TIME_TOKEN } from './core/tokens/ssr-request-received-time.token';
import { SSR_REQUEST_RECEIVED_TIME_KEY } from './core/transfer-state-keys/ssr-request-received-time.key';

@NgModule({
  imports: [AppModule],
  providers: [
    // provideClientHydration(),
    provideAppCheck(() =>
      initializeAppCheck(getApp(), {
        provider: new ReCaptchaEnterpriseProvider(
          environment.recaptchaAppCheckKey,
        ),
        isTokenAutoRefreshEnabled: false, // this is also the default
      }),
    ),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: AppCheckInterceptor, multi: true },
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(), // allows non-functional interceptors (eventually remove this once all interceptors are functional)
      withInterceptors([
        appCheckInterceptor,
        ssrRequestReceivedTimeInterceptor,
      ]), // functional interceptors go here
    ),
    {
      provide: SSR_REQUEST_RECEIVED_TIME_TOKEN,
      useFactory: (transferState: TransferStateService) => {
        const now = Date.now().toString();
        const time = transferState.get(
          SSR_REQUEST_RECEIVED_TIME_KEY,
          `NOT-SET-ON-SERVER-${now}`, // if it wasn't set on the server, set it now so we can at least track the rest of the browser-side requests.
        );
        console.log('SSR_REQUEST_RECEIVED_TIME_KEY in BrowserModule:', time);
        return time;
      },
      deps: [TransferStateService],
    },
    // provideAuth(() => initializeAuth(getApp())),
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor() {
    console.log('AppBrowserModule constructor');
  }
}
