import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ECOM_USER_VERIFICATION_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-email-verification-modal',
  templateUrl: './email-verification-modal.component.html',
  styleUrl: './email-verification-modal.component.scss',
})
export class EmailVerificationModalComponent {
  public showForm = true;
  public showSpinner = false;
  public sendVerificationCodeResponse$: Observable<boolean> | null = null;
  public verifyEmailResponse$: Observable<boolean> | null = null;

  public code = '';

  private userVerificationService = inject(
    ECOM_USER_VERIFICATION_API_SERVICES.v2,
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { email: string },
    private dialogRef: MatDialogRef<EmailVerificationModalComponent>,
  ) {}

  public onChange(): void {
    if (this.code.length === 6) {
      this.onComplete();
    } else {
      this.verifyEmailResponse$ = null;
    }
  }

  private onComplete(): void {
    this.sendVerificationCodeResponse$ = null;
    this.showSpinner = true;
    this.verifyEmailResponse$ = this.userVerificationService
      .verifyEmail(this.data.email, parseInt(this.code))
      .pipe(
        map(() => true),
        tap((result) => {
          this.showSpinner = false;
          this.showForm = false;
          setTimeout(() => this.dialogRef.close({ success: result }), 2000);
        }),
        catchError(() => {
          this.showSpinner = false;
          return of(false);
        }),
      );
  }

  public onResend(): void {
    this.sendVerificationCodeResponse$ = this.userVerificationService
      .sendVerificationCode(this.data.email)
      .pipe(
        map(() => true),
        tap(() => (this.verifyEmailResponse$ = null)),
        catchError(() => of(false)),
      );
  }
}
