import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { VerificationCodeV2Types as v2 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomUserVerification_V2_ApiService {
  private readonly apiUrl = '/api/2/userVerification';

  private readonly httpOptions = {
    ...requireAppCheck,
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       UPDATE                       *
   ******************************************************/

  public sendVerificationCode(
    email: string,
  ): Observable<v2.SendVerificationCodeSuccessResponse> {
    const route = `${this.apiUrl}/sendVerificationCode/${email}`;

    return this.httpClient.put<v2.SendVerificationCodeSuccessResponse>(
      route,
      {},
      this.httpOptions,
    );
  }

  public verifyEmail(
    email: string,
    code: number,
  ): Observable<v2.VerifyEmailSuccessResponse> {
    const route = `${this.apiUrl}/verifyEmail/${email}/${code}`;

    return this.httpClient.put<v2.VerifyEmailSuccessResponse>(
      route,
      {},
      this.httpOptions,
    );
  }
}
