import { FilterCategory } from 'common-types';

export const filterCategories: FilterCategory[] = [
  {
    attribute: 'colorFamily',
    displayName: 'Color Family',
    options: [
      {
        value: 'Black',
      },
      {
        value: 'Grey/Silver',
      },
      {
        value: 'White/Ivory',
      },
      {
        value: 'Tan/Chocolate',
      },
      {
        value: 'Red/Burgundy',
      },
      {
        value: 'Blue',
      },
      {
        value: 'Green',
      },
    ],
  },
  {
    attribute: 'ensembleGroup',
    displayName: 'Rent or Buy',
    options: [
      {
        value: 'Rental/Purchase',
      },
    ],
  },
  {
    attribute: 'designer',
    displayName: 'Designer',
    options: [
      {
        value: 'Michael Kors',
      },
      {
        value: 'Stephen Geoffrey',
      },
      {
        value: 'Mark of Distinction',
      },
      {
        value: 'Ike Behar',
      },
      {
        value: 'Allure Men',
      },
      {
        value: 'Couture 1910',
      },
      {
        value: 'Kenneth Cole',
      },
      {
        value: 'Lord West',
      },
    ],
  },
];
