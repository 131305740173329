import { isPlatformServer } from '@angular/common';
import {
  inject,
  Injectable,
  PLATFORM_ID,
  StateKey,
  TransferState,
} from '@angular/core';
import { ECOMMERCE_MAIN_ENVIRONMENT } from '../../tokens/ecommerce-main-environment.token';
import { SSR_CONTEXT, SSRContext } from '../../tokens/ssr-context.token';

/**
 * This is a wrapper around the Angular TransferState service.
 * It provides a way to set and get TransferState values, but logic
 * for when to set the TransferState is based on the current SSRContext
 * and the environment configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class TransferStateService {
  private transferState = inject(TransferState);
  private readonly environment = inject(ECOMMERCE_MAIN_ENVIRONMENT);
  private readonly isServer = isPlatformServer(inject(PLATFORM_ID));
  private readonly ssrContext = this.isServer
    ? inject(SSR_CONTEXT)
    : SSRContext.NotSSR;
  private readonly alwaysEnabledInThisContext =
    this.ssrContext === SSRContext.SSRFunction;

  private getTransferStateDisabledValue(
    transferStateKey: StateKey<any>,
  ): boolean {
    return this.environment.disableTransferState?.[transferStateKey] ?? false;
  }

  public getTransferStateDisabledForKey(
    transferStateKey: StateKey<any>,
  ): boolean {
    const disabledInEnvironment =
      this.getTransferStateDisabledValue(transferStateKey);
    // Only disable if we are NOT in the SSR function AND the disabled is set to true in the environment
    return this.alwaysEnabledInThisContext ? false : disabledInEnvironment;
  }

  /**
   * Set the TransferState for a specific TransferState key.
   * Will only set the TransferState when allowed to do so based on the current
   * SSRContext and the environment configuration.
   * @param transferStateKey The TransferState key to set
   * @param value the value to set
   * @returns
   */
  public set<T>(transferStateKey: StateKey<T>, value: T): void {
    const disabled = this.getTransferStateDisabledForKey(transferStateKey);
    if (disabled) {
      console.log('TransferState is disabled for key:', transferStateKey);
      return;
    }
    if (this.alwaysEnabledInThisContext) {
      console.warn(
        'disableTransferState is ignored in this ssrContext:',
        this.ssrContext,
      );
    }

    console.log('Setting transfer state for key:', transferStateKey);
    return this.transferState.set(transferStateKey, value);
  }

  public get = this.transferState.get.bind(this.transferState);

  public hasKey = this.transferState.hasKey.bind(this.transferState);

  public get isEmpty(): boolean {
    return this.transferState.isEmpty;
  }

  public onSerialize = this.transferState.onSerialize.bind(this.transferState);

  public remove = this.transferState.remove.bind(this.transferState);

  public toJson = this.transferState.toJson.bind(this.transferState);
}
