import { AfterViewInit, Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  EnsembleService,
  NavigationService,
  StyleService,
} from '@jfw-library/ecommerce/core';

@Component({
  selector: 'app-customize-look-filters',
  templateUrl: './customize-look-filters.component.html',
  styleUrls: ['./customize-look-filters.component.scss'],
})
export class CustomizeLookFiltersComponent implements AfterViewInit {
  disableAnimation = signal(true);
  showStylesFilters = toSignal(this.styleService.showFilter, {
    initialValue: false,
  });
  showEnsembleFilters = toSignal(this.ensembleService.showFilter, {
    initialValue: false,
  });

  constructor(
    private navigationService: NavigationService,
    private styleService: StyleService,
    private ensembleService: EnsembleService,
  ) {}

  ngAfterViewInit(): void {
    // workaround for angular component issue #13870
    setTimeout(() => this.disableAnimation.set(false));
  }

  close(): void {
    this.styleService.showFilter.next(false);
    this.ensembleService.showFilter.next(false);
    this.navigationService.showPlpNav.next(false);
  }
}
