import { DOCUMENT, Location } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { EcommerceMainEnvironment } from 'common-types';
@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  private document = inject(DOCUMENT);
  private location = inject(Location);
  constructor(
    @Inject('environment')
    private readonly environment: EcommerceMainEnvironment,
  ) {}

  setCanonicalURL(path?: string) {
    const locationPath = this.location.path(false);
    console.log('setCanonicalURL');
    console.log('location path', locationPath);
    /**
     * CANONICAL
     * Documentation: https://developers.google.com/search/docs/crawling-indexing/consolidate-duplicate-urls
     *
     * Purpose of this function is to set the urls we want to be the main url that appears on search engines.
     * If there are multiple sites IE: www.example-1.com, www.example-2.com we want only one of these to be canonical.
     * Below we are setting everything to be canonical except in production we want:
     *  - https://jimsformalwear.com to be the main url even though we have multiple domains
     **/

    let canonicalUrl = `${this.environment.canonicalUrl}${path ?? locationPath}`;

    let currentCanon = this.document.querySelector("link[rel='canonical']");

    // allow test to work the same as prod (for testing purposes)
    if (
      this.environment.envVersion === 'test' ||
      this.environment.envVersion === 'prod'
    ) {
      canonicalUrl = `${this.environment.ecom_prefix_for_email_and_sms_communication}${locationPath}`;
    }
    // console.log('canonicalUrl', canonicalUrl);
    // console.log('pathname', pathname);

    // If allowIndexing is false we will never have canonicalUrl break here
    if (this.environment.allowIndexing === false) {
      this.removeCanonicalUrl();
      return;
    }

    if (currentCanon) {
      currentCanon.setAttribute('href', canonicalUrl);
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', canonicalUrl);
    }
  }

  removeCanonicalUrl() {
    console.log('removeCanonicalUrl');
    let currentCanon = this.document.querySelector("link[rel='canonical']");
    if (currentCanon) {
      currentCanon.remove();
    }
  }
}
