import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { estypes as et } from '@elastic/elasticsearch';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  Category,
  CategoryItem,
  ElasticResponseEcomEnsemblePLP,
  FilterCategory,
  GeoBoundingBox,
  GlobalSearchPaginatorOptions,
  PaginatorOptions,
  SearchCriteria,
  StyleType,
} from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private ELASTIC_REST_API_SERVER =
    this.environment.elastic_rest_api_server_url;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) {}

  public getDistinctFiltersByCategories(
    index: string,
    categoryItem: Category,
    categories: FilterCategory[],
  ): Observable<FilterCategory[]> {
    if (!this.isBrowser) {
      console.log('SKIPPED API from Server > getDistinctFiltersByCategories()');
      return new Observable<FilterCategory[]>();
    }

    const apiUrl = this.ELASTIC_REST_API_SERVER + '/filters/category';
    let body = {
      index: index,
      categories,
      categoryItem: categoryItem,
    };
    return this.httpClient.post<Array<FilterCategory>>(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getDistinctFiltersByStyleType(
    styleType: StyleType,
    categories: FilterCategory[],
  ): Observable<FilterCategory[]> {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/filters/style-type';
    let body = {
      index: 'ecom-styles',
      categories,
      styleType: styleType,
    };
    return this.httpClient.post<Array<FilterCategory>>(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getSearchCriteriaForStyles(
    categoryItem: CategoryItem | undefined,
    filterCategories: FilterCategory[],
  ): SearchCriteria[] {
    const searchCriteria: SearchCriteria[] = [];

    if (categoryItem) {
      if (categoryItem.category === Category.ShoesAndSocks) {
        // rental shoe override - will remove when
        // purchase-only product is enabled on ecom
        searchCriteria.push({
          attribute: 'categories',
          // values: [Category.Shoes, Category.Socks, Category.PurchaseShoes],
          values: [Category.Shoes, Category.Socks],
        });
      } else {
        searchCriteria.push({
          attribute: 'categories',
          values: [categoryItem.category],
        });

        // accessories override - include both rental and purchase
        // will remove if statement when purchase-only product is enabled on ecom
        // future state: search by category and styleGroup - no overrides
        if (categoryItem.category !== Category.Accessories) {
          // searchCriteria.push({
          //   attribute: 'styleGroup',
          //   values: [this.styleGroup],
          // });
        }
      }
    }

    //
    filterCategories.forEach((category) => {
      const criteria: SearchCriteria = {
        attribute: category.attribute,
        values: [],
      };
      category.options?.forEach((option) => {
        if (option.selected) {
          criteria.values.push(option.value);
        }
      });
      if (criteria.values.length > 0) {
        searchCriteria.push(criteria as SearchCriteria);
      }
    });
    return searchCriteria;
  }

  public style(
    attributes: Array<SearchCriteria>,
    paginatorOptions?: PaginatorOptions,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search';
    let body = {
      index: 'ecom-styles',
      searchCriteria: attributes,
      paginatorOptions,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public ensemble(
    attributes: Array<SearchCriteria>,
    paginatorOptions?: PaginatorOptions,
    dealerPortal: boolean = false,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search';
    let body = {
      index: 'ecom-ensembles',
      searchCriteria: attributes,
      paginatorOptions,
      dealerPortal,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public ensemblePlp(
    attributes: Array<SearchCriteria>,
    paginatorOptions?: PaginatorOptions,
    dealerPortal: boolean = false,
  ): Observable<ElasticResponseEcomEnsemblePLP> {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search-ensemble-plp';
    let body = {
      index: 'ecom-ensembles',
      searchCriteria: attributes,
      paginatorOptions,
      dealerPortal,
    };
    return this.httpClient.post<ElasticResponseEcomEnsemblePLP>(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getAllDocumentsByQueryString(
    index: string,
    queryString: string,
    fields: Array<string>,
    dealerPortal: boolean = false,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/query-string';
    let body = {
      index,
      queryString,
      fields,
      dealerPortal,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getCustomizeLookStyles(
    attributes: Array<SearchCriteria>,
    paginatorOptions?: PaginatorOptions,
    selectedStyleCode?: string,
    matchingStyleCodes?: Array<string>,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search-customize-looks';
    let body = {
      index: 'ecom-styles',
      searchCriteria: attributes,
      paginatorOptions,
      selectedStyleCode,
      matchingStyleCodes,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getStyleTypeDocumentsByQueryString(
    index: string,
    styleType: StyleType,
    queryString: string,
    fields: Array<string>,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl =
      this.ELASTIC_REST_API_SERVER +
      '/style-type/' +
      styleType +
      '/query-string/' +
      queryString;
    let body = {
      index,
      queryString,
      styleType,
      fields,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public globalProductSearch(
    searchString: string,
    paginatorOptions?: GlobalSearchPaginatorOptions,
  ): Observable<
    et.MsearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    // This typing doesn't seem to exist for our use case. MsearchResponse
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search-text';
    let body = {
      searchString: searchString,
      paginatorOptions: paginatorOptions,
    };
    return this.httpClient.post<
      et.MsearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getStoreByActNum(
    actNum: string,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/store/' + actNum;
    let body = {
      index: 'dealer-accounts',
      actNum: actNum,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, { ...requireAppCheck, headers: this.reqHeader });
  }

  public getStoresByBoundingBox(
    centerLat: number,
    centerLng: number,
    geoBoundingBox: GeoBoundingBox,
  ): Observable<
    et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
  > {
    const apiUrl = this.ELASTIC_REST_API_SERVER + '/search-store';
    let body = {
      index: 'dealer-accounts',
      center: {
        centerLat,
        centerLng,
      },
      searchCriteria: geoBoundingBox,
    };
    return this.httpClient.post<
      et.SearchResponse<unknown, Record<string, et.AggregationsAggregate>>
    >(apiUrl, body, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }
}
