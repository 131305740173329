<div class="filter-main" [@.disabled]="disableAnimation()">
  <div class="sidenav-header">
    <h2>Filter</h2>
    <button
      id="close-filter-button"
      mat-icon-button
      (click)="close()"
      aria-label="Close Filter"
      data-testid="close-filter-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-style-filters
    *ngIf="showStylesFilters()"
    data-testid="style-filters"
  ></app-style-filters>
  <app-ensemble-filters
    *ngIf="showEnsembleFilters()"
    data-testid="ensemble-filters"
  ></app-ensemble-filters>
</div>
