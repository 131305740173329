import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { StyleService } from '@jfw-library/ecommerce/core';
import { setColorClass } from 'business-logic';
import { FilterCategory } from 'common-types';

@Component({
  selector: 'app-style-filters',
  templateUrl: './style-filters.component.html',
  styleUrls: ['./style-filters.component.scss'],
})
export class StyleFiltersComponent {
  filterCategories = toSignal(this.styleService.filterCategories);
  colorMap: Record<string, string> = {};
  constructor(private styleService: StyleService) {}

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  rentOrBuyMask(option: string): string {
    let maskedOption = option;
    switch (option) {
      case 'Rental':
        maskedOption = 'Rent';
        break;
      case 'Purchase':
        maskedOption = 'Buy';
        break;
      case 'Rental/Purchase':
        maskedOption = 'Rent/Buy';
        break;
    }
    return maskedOption;
  }

  public optionClicked(
    category: FilterCategory,
    categoryIndex: number,
    optionIndex: number,
  ): void {
    // Single Selector logic
    // if (category.select === FilterSelect.Single) {
    //   this.filterCategories![categoryIndex].options?.forEach(
    //     (option, index) => {
    //       if (index !== optionIndex) {
    //         option.selected = false;
    //       }
    //     }
    //   );
    // }
    this.styleService.searchStyles.next();
  }
}
