import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService implements OnDestroy {
  private previousUrl: string | undefined;
  private currentUrl: string;
  private subscription = new Subscription();
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.subscription.add(
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
}
