import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ECOM_EVENT_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import {
  AuthService,
  EventService,
  SignInModalComponent,
} from '@jfw-library/ecommerce/core';
import { generateUUID } from 'business-logic';
import {
  AfterSignIn,
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Event,
  Look,
  UserEvent,
} from 'common-types';
import { firstValueFrom, of, switchMap, tap } from 'rxjs';
import {
  EventModalComponent,
  EventModalResult,
} from '../../components/event-modal/event-modal.component';
import {
  NewEventModalComponent,
  NewEventModalComponentResult,
} from '../../components/new-event-modal/new-event-modal.component';
import {
  EventModalComponentData,
  NewEventModalComponentData,
} from '../../types';
import { EcommerceSharedModuleEventService } from '../event/shared/ecommerce-shared-module-event.service';

@Injectable({
  providedIn: 'root',
})
export class LookService {
  private ecomUpdateEventApiService = inject(ECOM_EVENT_API_SERVICES.v8);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    @Inject('environment')
    private readonly environment:
      | EcommerceMainEnvironment
      | DealerPortalEnvironment,
    private authService: AuthService,
    private eventService: EventService,
    private ecommerceSharedModuleEventService: EcommerceSharedModuleEventService,
    private dialog: MatDialog,
  ) {}
  /// EXT-2445
  /// MOVED THESE TO EVENT MANAGER SERVICE
  // public selectLook(eventId: string, selectedLookId: string) {
  //   return firstValueFrom(
  //     this.ecomUpdateEventApiService.selectLook(eventId, selectedLookId)
  //   );
  // }

  // public copyLook(eventId: string, lookId: string) {
  //   return firstValueFrom(
  //     this.ecomUpdateEventApiService.copyLook(eventId, lookId)
  //   );
  // }

  // public deleteLook(eventId: string, lookId: string) {
  //   return firstValueFrom(
  //     this.ecomUpdateEventApiService.deleteLook(eventId, lookId)
  //   );
  // }

  // public assignLooks(
  //   eventId: string,
  //   data: Parameters<typeof this.ecomUpdateEventApiService.assignLooks>[1]
  // ) {
  //   return firstValueFrom(
  //     this.ecomUpdateEventApiService.assignLooks(eventId, data)
  //   );
  // }

  public async getLookFromEvent(
    eventId: string,
    lookId: string,
  ): Promise<Look | undefined> {
    return new Promise(async (resolve, reject) => {
      const event = await this.eventService.setSelectedEvent(
        eventId,
        'LookService -- getLookFromEvent()',
      );

      const lookIndex = event.looks.findIndex((look) => {
        return lookId === look.id;
      });
      if (lookIndex > -1) {
        resolve(event.looks[lookIndex]);
      } else {
        resolve(undefined);
      }
    });
  }

  public promptEventModalDialog(newLook: Look, futureUserEvents: UserEvent[]) {
    return firstValueFrom(
      this.dialog
        .open<EventModalComponent, EventModalComponentData>(
          EventModalComponent,
          {
            data: { newLook, futureUserEvents },
            autoFocus: false,
            minHeight: '400px',
            maxWidth: '340px',
            disableClose: true,
          },
        )
        .afterClosed()
        .pipe(
          switchMap((result: EventModalResult) => {
            /**
             * After EventModal closes with Event or Undefined If Event we use setSelectedEvent (This is used for savingLook from LookService)
             * IMPORTANT: Note this modal stays open when selecting (new event) to maintain synchronous process.
             * NewEventModal can pop from inside EventModal.
             */
            if (result?.eventId) {
              return this.eventService.setSelectedEvent(result.eventId);
            }
            return of(undefined);
          }),
          tap((result) => {
            if (result) {
              // Route to step after pipe above finishes
              this.eventService.routeToFirstStep();
            }
          }),
        ),
    );
  }

  // event creation is disabled for now
  // all events are created in dealer portal
  public promptNewEventModalDialog(
    newLook: Look,
    futureUserEvents: UserEvent[],
  ) {
    return firstValueFrom(
      this.dialog
        .open<
          NewEventModalComponent,
          NewEventModalComponentData,
          NewEventModalComponentResult
        >(NewEventModalComponent, {
          data: { newLook, futureUserEvents, redirectToEvent: true },
          autoFocus: false,
          width: '340px',
          maxWidth: '340px',
          disableClose: true,
        })
        .afterClosed(),
    );
  }

  private async saveLookHaveEvent(look: Look, event: Event) {
    const eventLookIndex = event.looks.findIndex((eventLook) => {
      return eventLook.id === look.id;
    });

    if (eventLookIndex > -1) {
      // look already exists in event, so update it
      console.log(
        'look.service.ts - saveLookHaveEvent - look already exists in event, so update it',
      );

      try {
        const { updatedEvent } = await firstValueFrom(
          this.ecomUpdateEventApiService.updateLookAndEventMemberLooks(
            event.id,
            { look },
          ),
        );
        console.log(
          'Look updated successfully.  setting selected event with updatedEvent...',
        );
        this.eventService.setSelectedEventWithEvent(
          updatedEvent,
          'LookService -- saveLookHaveEvent after updateLookAndEventMemberLooks',
        );

        //await this.eventService.routeToFirstStep();
        return updatedEvent;
      } catch (error) {
        console.error('Error updating look and event member looks', error);
        throw error;
      }
    } else {
      // look does not exist in event, so add it
      console.log(
        'look.service.ts - saveLookHaveEvent - look does not exist in event, so add it',
      );

      try {
        const lookWithId = { ...look, id: generateUUID(this.isBrowser) };
        const { updatedEvent } = await firstValueFrom(
          this.ecomUpdateEventApiService.addLook(event.id, {
            look: lookWithId,
          }),
        );
        console.log(
          'Look added successfully.  setting selected event with updatedEvent...',
        );
        this.eventService.setSelectedEventWithEvent(
          updatedEvent,
          'LookService -- saveLookHaveEvent after addLook',
        );
        //await this.eventService.routeToFirstStep();
        return updatedEvent;
      } catch (error) {
        console.error('Error adding look to event', error);
        throw error;
      }
    }
  }

  private async saveLookNeedEvent(look: Look) {
    console.log('saveLookNeedEvent in LookService.');

    // First check to see if there are events in memory already
    const lsUserEvents: UserEvent[] | null = JSON.parse(
      localStorage.getItem('user_events')!,
    );

    // get userEvents from localStorage first, otherwise get from API
    const userEvents =
      lsUserEvents ??
      (await this.ecommerceSharedModuleEventService.getUserEventsPromise());

    // If user has userEvents, filter for future events
    const futureUserEvents: UserEvent[] = userEvents
      ? this.ecommerceSharedModuleEventService.getFilteredFutureUserEvents(
          userEvents,
        )
      : [];

    if (futureUserEvents.length > 0) {
      // has future events
      console.log('future events found - new event is not needed');
      // This will open EventModal and potentially NewEventModal
      return this.promptEventModalDialog(look, futureUserEvents);
    }

    // if no future events, prompt user to create a new event
    console.log('no future events - new event is needed');
    if (this.environment.dealerPortal ? false : true) {
      return this.promptNewEventModalDialog(look, futureUserEvents);
    }

    console.log('Event Creation is disabled.  Not opening New Event Modal');
    return;
  }

  public async saveLookToEvent(
    look: Look,
    event?: Event,
  ): Promise<Event | undefined> {
    // There might be a selectedEvent even when the event argument here is undefined.
    if (event) {
      return this.saveLookHaveEvent(look, event);
    } else {
      // an event was not passed as a queryParam
      // the user must select an event or create a new one
      look.id = generateUUID(this.isBrowser);
      const isLoggedIn = await this.authService.isLoggedIn;
      if (!isLoggedIn) {
        this.dialog.open(SignInModalComponent, {
          data: { afterSignIn: AfterSignIn.SaveLookToEvent },
          autoFocus: false,
          maxWidth: '340px',
        });
        return undefined;
      } else {
        const result = await this.saveLookNeedEvent(look);
        return result;
      }
    }
  }
}
