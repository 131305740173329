import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@jfw-library/ecommerce/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { catchError, from, Observable, switchMap } from 'rxjs';

export interface AnonToken {
  userId: string;
  jwtToken: string;
}

/**
 * This interceptor is used to add the Bearer token to the request's Authorization header
 * or the AnonJwtToken and AnonSessionUserId headers for anonymous users.
 * 1. It first checks if the user is logged in, and if so, adds the user's firebase JWT as the Bearer token in the request's Authorization headers.
 * 2. If the user is not logged in, it checks for the anonUser in local storage, and if found, adds the anonUser's JWT as the Bearer token request's Authorization headers.
 * 3. If the user is not logged in, and the anonUser is not found in local storage,
 *  it checks for the the AnonToken values in the token_prod and token_prod_session_userid cookies,
 * and if found, adds the AnonToken as two request headers: AnonJwtToken and AnonSessionUserId.
 * 4. If none of the above are found, it does not add any request headers, which would cause api calls that have the authMiddleware or the authMiddlewareAllowAnon to fail.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  constructor(private cookieService: SsrCookieService) {
    //console.log('token interceptor constructor');
  }

  /**
   * The AnonToken is an object that contains the userId and a JWT from the validation api, both stored in cookies.
   * The JWT encodes an object with a userId property, which should match the userId property in the AnonToken.
   * The userId is stored in the token_prod_session_userid cookie.
   * The JWT is stored in the token_prod cookie.
   *
   * @returns AnonToken object, or undefined if either the token_prod or token_prod_session_userid cookies do not exist.
   */
  getAnonToken(): AnonToken | undefined {
    //return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJ0ZXN0VXNlciIsImlhdCI6MTY4NTc5NzY1MSwiZXhwIjoxNjg1ODAxMjUxfQ.3M3Fq5HxpjxKn1cO7ldTr-FHdkJ7ijBcHywrr36ZoA4';

    if (
      !this.cookieService.check('token_prod') ||
      !this.cookieService.check('token_prod_session_userid')
    ) {
      return undefined;
    }

    let tokenCookie = this.cookieService.get('token_prod');
    let sessionUserIdCookie = this.cookieService.get(
      'token_prod_session_userid',
    );
    // console.log('Token', tokenCookie);
    // console.log('token_prod_session_userid', sessionUserIdCookie);
    if (tokenCookie && sessionUserIdCookie) {
      let anonToken: AnonToken = {
        userId: sessionUserIdCookie,
        jwtToken: tokenCookie,
      };
      return anonToken;
    } else {
      return undefined;
    }
  }

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   // Get the auth token from the service.
  //   const anonToken = this.getAnonToken();

  //   // Clone the request and replace the original headers with
  //   // cloned headers, updated with the authorization.
  //   // const authReq = request.clone({
  //   //   headers: request.headers.set('Authorization', authToken)
  //   // });

  //   let authReq = request;

  //   if (anonToken !== undefined) {
  //     authReq = request.clone({
  //       setHeaders: {
  //         AnonJwtToken: `${anonToken.jwtToken}`,
  //         AnonSessionUserId: `${anonToken.userId}`,
  //       },
  //     });
  //   }

  //   // Send cloned request with header to the next handler.
  //   return next.handle(authReq);
  // }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('TokenInterceptor begin intercept');
    return from(this.authService.idToken).pipe(
      switchMap((idToken) => {
        console.log('idToken', !idToken ? 'not found' : 'found');
        if (idToken) {
          // user is logged in, so use the firebase JWT
          console.log('DEBUG: Token Interceptor using Bearer');

          request = request.clone({
            setHeaders: { Authorization: `Bearer ${idToken}` },
          });

          return next.handle(request);
        }

        console.log('DEBUG: Token Interceptor using AnonToken');
        const anonToken = this.getAnonToken();

        if (anonToken !== undefined) {
          request = request.clone({
            setHeaders: {
              AnonJwtToken: `${anonToken.jwtToken}`,
              AnonSessionUserId: `${anonToken.userId}`,
            },
          });
        } else {
          console.warn('AnonToken is undefined and user is not logged in.');
        }

        return next.handle(request);
      }),
      // // catchError((error) => {
      // //   console.error('Error in Interceptor', { request, error });
      // //   return next.handle(request);
      // // }),
    );

    //   try {
    //     const idToken = this.authService.idToken;
    //     if (idToken) {
    //       // user is logged in, so use the firebase JWT
    //       console.log('DEBUG: Token Interceptor using Bearer');

    //       request = request.clone({
    //         setHeaders: { Authorization: `Bearer ${idToken}` },
    //       });

    //       return next.handle(request);
    //     }

    //     console.log('DEBUG: Token Interceptor using Anon Token');
    //     const anonToken = this.getAnonToken();

    //     if (anonToken !== undefined) {
    //       request = request.clone({
    //         setHeaders: {
    //           AnonJwtToken: `${anonToken.jwtToken}`,
    //           AnonSessionUserId: `${anonToken.userId}`,
    //         },
    //       });
    //     } else {
    //       console.warn('AnonToken is undefined and user is not logged in.');
    //     }

    //     return next.handle(request);

    //   } catch (error: any) {
    //     console.error('Error in Interceptor', { request, error });
    //     return next.handle(request);
    //   }
  }
}
