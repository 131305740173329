import { ServiceMap } from './src/util';

/// ************ EVENT API SERVICES ************
import { EcomEvent_V7_ApiService, EcomEvent_V8_ApiService } from './src/event';

export const ECOM_EVENT_API_SERVICES = {
  v7: EcomEvent_V7_ApiService,
  v8: EcomEvent_V8_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_EVENT_API_SERVICES;

/// ************ USER EVENT API SERVICES ************
import {
  EcomUserEvent_V3_ApiService,
  EcomUserEvent_V4_ApiService,
} from './src/user-event';

export const ECOM_USER_EVENT_API_SERVICES = {
  v3: EcomUserEvent_V3_ApiService,
  v4: EcomUserEvent_V4_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_USER_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_USER_EVENT_API_SERVICES;

/// ************ DEALER USER EVENT API SERVICES ************
import {
  EcomDealerUserEvent_V3_ApiService,
  EcomDealerUserEvent_V4_ApiService,
} from './src/dealer-user-event';

export const ECOM_DEALER_USER_EVENT_API_SERVICES = {
  v3: EcomDealerUserEvent_V3_ApiService,
  v4: EcomDealerUserEvent_V4_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_DEALER_USER_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_DEALER_USER_EVENT_API_SERVICES;

/// ************ DEALER USER API SERVICES ************
import {
  EcomDealerUser_V1_ApiService,
  EcomDealerUser_V2_ApiService,
} from './src/dealer-user';

export const ECOM_DEALER_USER_API_SERVICES = {
  v1: EcomDealerUser_V1_ApiService,
  v2: EcomDealerUser_V2_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_DEALER_USER_API_SERVICES_VERSIONS =
  keyof typeof ECOM_DEALER_USER_API_SERVICES;

/// ************ INVITE API SERVICES ************
import {
  EcomInvite_V3_ApiService,
  EcomInvite_V4_ApiService,
} from './src/invite';

export const ECOM_INVITE_API_SERVICES = {
  v3: EcomInvite_V3_ApiService,
  v4: EcomInvite_V4_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_INVITE_API_SERVICES_VERSIONS =
  keyof typeof ECOM_INVITE_API_SERVICES;

/// ************ CALENDAR API SERVICES ************
import { EcomCalendar_V2_ApiService } from './src/calendar';

export const ECOM_CALENDAR_API_SERVICES = {
  v2: EcomCalendar_V2_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_CALENDAR_API_SERVICES_VERSIONS =
  keyof typeof ECOM_CALENDAR_API_SERVICES;

/// ************ USER VERIFICATION API SERVICES ************
import { EcomUserVerification_V1_ApiService } from './src/user-verification';
import { EcomUserVerification_V2_ApiService } from './src/user-verification/ecom-user-verification-v2-api.service';

export const ECOM_USER_VERIFICATION_API_SERVICES = {
  v1: EcomUserVerification_V1_ApiService,
  v2: EcomUserVerification_V2_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_USER_VERIFICATION_API_SERVICES_VERSIONS =
  keyof typeof ECOM_USER_EVENT_API_SERVICES;
