import { makeStateKey } from '@angular/core';

/**
 * This key is used to store the time that the initial page request was received on the SSR server function
 * so that the same value can be used when setting the 'ssr-request-received-time'
 * header on all requests made from the browser.
 * It is used to track the series of api requests that are made from a specific SSR function request.
 */
export const SSR_REQUEST_RECEIVED_TIME_KEY = makeStateKey<string>(
  'SSR_REQUEST_RECEIVED_TIME_KEY',
);
