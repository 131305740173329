import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { EcomStyle, SearchCriteria } from 'common-types';
import { catchError, delay, EMPTY, of, switchMap, tap } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
import { TransferStateService } from '../../services/transfer-state/transfer-state.service';
import { STYLE_DETAIL_KEY } from '../../transfer-state-keys/style-detail.key';

const DEBUG = {
  simulateDelay: isDevMode() && false, // if true, will simulate a delay in the resolver
  simulateServerError: isDevMode() && false, // if true, will simulate an error in the resolver
};

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StyleResolver implements Resolve {
  private search = inject(SearchService);
  private seoService = inject(SEOService);
  private router = inject(Router);
  private transferState = inject(TransferStateService);
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  constructor() {}
  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }
  private getSearchCriteria(params: Params): Array<SearchCriteria> {
    const { styleCode } = params;
    let searchCriteria: Array<SearchCriteria> = [];
    let ensembleGroupCodeSearch = {
      index: 'ecom-styles',
      attribute: 'styleCode',
      values: [styleCode],
    };
    searchCriteria.push(ensembleGroupCodeSearch);
    return searchCriteria;
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let styleCodeString: string = state.url;
    let styleCodeArray = styleCodeString.split('-');
    let styleCode = '';
    let setMetaTags = true;
    const updatedParams = {
      styleCode: styleCodeArray[styleCodeArray.length - 1],
    };
    console.log('Style Code String:', styleCodeString);
    // This if statement is to know that we came from products search page skip the api call below.
    if (styleCodeArray[styleCodeArray.length - 1].includes('searchText')) {
      styleCodeArray = styleCodeString.split('/');
      updatedParams.styleCode =
        styleCodeArray[styleCodeArray.length - 1].split('?')[0];
      setMetaTags = false;
      console.log('IF STATEMENT SECTION > 1');
    } else if (styleCodeString.includes('-')) {
      // Entered from PLP
      updatedParams.styleCode = styleCodeArray[styleCodeArray.length - 1];
      setMetaTags = true;
      console.log('IF STATEMENT SECTION > 2');
    } else {
      // This is entered from colorchips in PDP
      styleCodeArray = styleCodeString.split('/');
      updatedParams.styleCode = styleCodeArray[styleCodeArray.length - 1];
      setMetaTags = false;
      console.log('IF STATEMENT SECTION > 3');
    }
    console.log('Style Code Before Style Service Invocation:', styleCode);
    const styleFromTransferState = this.transferState.get(
      STYLE_DETAIL_KEY,
      null,
    );

    const useTransferStateData =
      styleFromTransferState !== null &&
      styleFromTransferState.styleCode === updatedParams.styleCode; // styleCode will be different when a navigation triggers this resolver (as opposed to initial page load)

    console.log('Use data from Transfer State:', useTransferStateData);

    const styleFromApi$ = this.search
      .style(this.getSearchCriteria(updatedParams))
      .pipe(
        delay(DEBUG.simulateDelay && !this.isServer ? 3000 : 0),
        switchMap((elasticStyle) => {
          if (DEBUG.simulateServerError && this.isServer) {
            console.warn('Simulating Server Error');
            throw new Error('Simulated Server Error');
          }
          if (elasticStyle.hits.hits.length > 0) {
            if (elasticStyle.hits.hits.length > 1) {
              throw new Error('More than one style found');
            }
            const style = elasticStyle.hits.hits[0]._source as EcomStyle;
            // Set the style in transfer state if it is not already set
            if (this.isServer) {
              console.log('Setting Style in Transfer State:', style.styleCode);
              this.transferState.set(STYLE_DETAIL_KEY, style);
            }
            return of(style);
          } else {
            console.log('No Styles Found');
            if (!this.isServer) {
              this.router.navigate(['/home']);
            }
            return EMPTY;
          }
        }),
      );

    const styleSource$ = useTransferStateData
      ? of(styleFromTransferState)
      : styleFromApi$;

    return styleSource$.pipe(
      tap((style) => {
        if (setMetaTags) {
          const metaData: MetaInfo = {
            metaDescription: undefined,
            metaTitle: undefined,
            noIndex: true,
            metaKeywords: undefined,
          };
          if (style) {
            const title = style?.marketingTitle;
            let metaDescription = style.metaDescription;
            if (
              metaDescription === undefined ||
              metaDescription === null ||
              metaDescription === ''
            ) {
              metaDescription = this.getFilteredDescriptionForMeta(
                style.marketingDescription,
              );
            }

            const keywords = style.keywords
              ? style.keywords.join(',')
              : undefined;

            metaData.metaDescription = metaDescription;
            metaData.metaTitle = title;
            metaData.noIndex = false;
            metaData.metaKeywords = keywords;
          } else {
            console.log('No style found, so noindex is true');
          }
          const { queryParams } = route;
          const isParameterizedRoute = Object.keys(queryParams).length > 0;

          if (isParameterizedRoute) {
            this.seoService.setNoIndexTag(true);
            /**
             * If noIndex is ever true, canonical should be removed
             * Default remove canonical.
             *  */
            this.seoService.setCanonicalTag(state.url, false);
          } else {
            this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
            this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
          }
          this.seoService.setMetaTags(metaData);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error in StyleResolver' + JSON.stringify(error));
        if (!this.isServer) {
          // don't return home page HTML if server errors, let the navigation happen browser side
          this.router.navigate(['/home']);
        }
        return EMPTY;
      }),
    );
  }
}
