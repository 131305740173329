import { InjectionToken } from '@angular/core';

export const SSRContext = {
  SSRFunction: 'SSR Function',
  Prerendering: 'Prerendering',
  NotSSR: 'Not SSR (Browser)',
} as const;
export type SSRContext = (typeof SSRContext)[keyof typeof SSRContext];

/** This token is used in conjunction with the IS_SSR_FUNCTION token so that the
 * app knows whether it is operating in the Express function
 * (rendered using commonEngine.render() in the server.ts) OR being prerendered (since server.ts is not used during pre-rendering).
 * The IS_SSR_FUNCTION token will not exist (and as such also not have a value) during pre-rendering,
 * so the existence of that token can be used to infer whether we are running in SSR or pre-rendering.
 *
 * NOTE: This token is only provided on the server, so it should only be injected on the server,
 * or as an option dependency in the constructor (see examples)
 * @example
 * class SomeClassOrService {
 *    // Option 1: Guard by isServer
 *    private ssrContext = this.isServer ? inject(SSR_CONTEXT) : null;
 *
 *    // Option 2: Use Optional() decorator in the constructor (remove the backticks around the `@` symbol)
 *    constructor(`@`Optional() `@`Inject(SSR_CONTEXT) private ssrContext: SSRContext) {}
 * }
 *
 *  */
export const SSR_CONTEXT = new InjectionToken<SSRContext>('SSR_CONTEXT');
