import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  DealerUserEvent,
  EcomDealerUserEventV4Types as v4,
} from 'common-types';
import { User } from 'firebase/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomDealerUserEvent_V4_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = 'api/4/dealerUserEvent';

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       CREATE                       *
   ******************************************************/

  /********************************************************
   *                       READ                           *
   ********************************************************/

  public getDealerUserEventByEventId(
    eventId: string,
  ): Observable<DealerUserEvent> {
    const url = `${this.apiUrl}/${eventId}`;

    return this.httpClient.get<v4.GetDealerUserEventByEventIdSuccessResponse>(
      url,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public getDealerUserEventsByUserId(
    userId: string,
    queryParams: HttpParams,
  ): Observable<DealerUserEvent[]> {
    const url = `${this.apiUrl}/user/${userId}`;

    return this.httpClient.get<v4.GetDealerUserEventsByUserIdSuccessResponse>(
      url,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
        params: queryParams,
      },
    );
  }

  public getNonTransferredEventsByUserId(
    userId: string,
  ): Observable<DealerUserEvent[]> {
    const url = `${this.apiUrl}/user/${userId}/non-transferred`;

    return this.httpClient.get<v4.GetNonTransferredEventsByUserIdSuccessResponse>(
      url,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public getEventsNotAcceptedByUserId(
    userId: string,
  ): Observable<DealerUserEvent[]> {
    const url = `${this.apiUrl}/user/${userId}/not-accepted`;

    return this.httpClient.get<v4.GetEventsNotAcceptedByUserIdSuccessResponse>(
      url,
      {
        ...requireAppCheck,
      },
    );
  }

  /********************************************************
   *                      UPDATE                          *
   ********************************************************/

  public updateDealerUserEventById(
    eventId: string,
    dealerUserEvent: DealerUserEvent,
  ): Observable<DealerUserEvent> {
    const url = `${this.apiUrl}/update/${eventId}`;

    const data: v4.UpdateDealerUserEventByIdData = { eventId, dealerUserEvent };

    return this.httpClient.put<v4.UpdateDealerUserEventByIdSuccessResponse>(
      url,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public markEventAsTransferred(
    eventId: string,
    user: User,
  ): Observable<DealerUserEvent> {
    const url = `${this.apiUrl}/transfer-event/${eventId}`;

    return this.httpClient.put<v4.MarkEventAsTransferredSuccessResponse>(
      url,
      {
        user,
      },
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  /********************************************************
   *                      DELETE                          *
   ********************************************************/
}
