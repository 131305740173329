import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import {
  ECOM_CALENDAR_API_SERVICES,
  ECOM_EVENT_API_SERVICES,
  ECOM_USER_EVENT_API_SERVICES,
} from '@jfw-library/ecommerce/api-services';
import { EventService } from '@jfw-library/ecommerce/core';
import { isUnixTimestampInPast } from 'business-logic';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Event,
  EventAdmin,
  EventDetails,
  EventTimeline,
  UserEvent,
} from 'common-types';
import dayjs from 'dayjs';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  delay,
  firstValueFrom,
  map,
  tap,
} from 'rxjs';

/** This service is for the components that are part of the EcommerceSharedModule.
 * It is not designed to be consumed outside of this EcommerceSharedModule,
 * so it should not be exposed through the module exports or through the public-api.
 * */
@Injectable({
  providedIn: 'root',
})
export class EcommerceSharedModuleEventService implements OnDestroy {
  public selectedEvent: Event | null = null;
  private subscription = new Subscription();
  private user: User | null = null;
  private gettingUserEvents = false;
  private userEvents: UserEvent[] = [];

  public selectedEvent$ = new BehaviorSubject<Event>({} as Event);
  public eventSaveError$ = new BehaviorSubject<boolean>(false);
  public canProceed$ = new Subject<boolean>();
  public nextClicked$ = new Subject<boolean>();
  public forceNextStep$ = new Subject<void>();
  public validateStep$ = new Subject<void>();

  private auth: Auth = inject(Auth);

  private ecomEventApiService = inject(ECOM_EVENT_API_SERVICES.v8);
  private eventService = inject(EventService);
  private ecomUserEventApiService = inject(ECOM_USER_EVENT_API_SERVICES.v3);
  private ecomCalendarApiService = inject(ECOM_CALENDAR_API_SERVICES.v2);

  constructor(
    @Inject('environment')
    private environment: DealerPortalEnvironment | EcommerceMainEnvironment,
  ) {
    this.subscription.add(
      authState(this.auth).subscribe((user) => {
        this.user = user;
      }),
    );
    console.log(
      'EcommerceSharedModuleEventService is using Event API version: ',
      this.ecomEventApiService.apiUrl,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async createEvent(event: Event): Promise<Event> {
    if (!this.user?.uid) {
      console.error('User not logged in');
      throw new Error('User not logged in');
    }

    // Creating an event for DP requires passing organizer info (from a form).
    // Since this function does not accept an organizer parameter, it is only to be used for Ecom.
    // This check is necessary because this is a Shared service.
    if (this.environment.dealerPortal) {
      throw new Error(
        'Events cannot be created from Dealer Portal using this function.',
      );
    }

    // Get the customLinkId stored in local storage (which should be the dealerId)
    // This is what identifies an event as Custom Link vs. Organic
    // If it exists, it will be treated as a Custom Link event.
    const dealerId = localStorage.getItem('customLinkId');

    if (dealerId) {
      console.log('customLinkId found:', dealerId, ' -- CUSTOM_LINK event');
    } else {
      console.log('No customLinkId found -- ORGANIC event');
    }

    const eventToCreate: Event = {
      ...event,
      createdByUserId: this.user.uid, // This is used to verify on the back end whether the createdByUserId in the payload matches the actual user (according to their Auth token)
      ...(dealerId ? { dealerId } : undefined), // If dealerId exists, add it to the event.
    };

    return firstValueFrom(
      this.ecomEventApiService
        .createEventFromEcom({ event: eventToCreate })
        .pipe(
          map(({ newEvent }) =>
            this.eventService.setSelectedEvent(
              newEvent.id,
              'EcommerceSharedModuleEventService - createEvent',
            ),
          ),
          tap((newEvent) => {
            localStorage.removeItem('checkout_state');
            console.log('routing to first step');
            this.eventService.routeToFirstStep();
          }),
        ),
    );
  }

  public updateOrganizer(eventId: string, adminId: string, admin: EventAdmin) {
    return firstValueFrom(
      this.ecomEventApiService.updateAdminDetails(eventId, adminId, admin),
    );
  }

  private setAllUserEventsLocalStorage(userEvents: UserEvent[]) {
    localStorage.setItem('user_events_updated', dayjs(Date.now()).toString());
    localStorage.setItem('user_events', JSON.stringify(userEvents));
  }

  public async getEventById(id: string): Promise<Event> {
    return (await firstValueFrom(this.ecomEventApiService.getEventById(id)))
      .event;
  }

  public async getUserEventsPromise(): Promise<UserEvent[] | undefined> {
    return firstValueFrom(
      this.ecomUserEventApiService
        .getUserEventsForCurrentUser()
        .pipe(delay(5000)),
    ).then((data) => {
      console.log('Getting UserEvents');
      this.gettingUserEvents = false;
      this.userEvents = data;
      this.setAllUserEventsLocalStorage(data);
      return data;
    });
  }

  public async getFutureUserEventsPromise(
    forceRefresh: boolean = false,
    preFetchedUserEvents: UserEvent[] | undefined = undefined,
  ): Promise<UserEvent[] | undefined> {
    const userId: string | undefined = this.user?.uid;

    return new Promise(async (resolve, reject) => {
      if (preFetchedUserEvents !== undefined && !forceRefresh) {
        let futureEvents: UserEvent[] =
          this.getFilteredFutureUserEvents(preFetchedUserEvents);
        resolve(futureEvents);
      } else {
        const futureEvents: UserEvent[] = [];

        await this.getUserEventsPromise().then((userEvents) => {
          if (userEvents !== undefined) {
            userEvents.forEach((event) => {
              if (!isUnixTimestampInPast(event.eventDate)) {
                futureEvents.push(event);
              }
            });

            resolve(futureEvents);
          } else {
            resolve(undefined);
          }
        });
      }
    });
  }

  public getFilteredFutureUserEvents(
    preFetchedUserEvents: UserEvent[],
  ): UserEvent[] {
    const futureEvents: UserEvent[] = [];

    preFetchedUserEvents.forEach((event) => {
      if (!isUnixTimestampInPast(event.eventDate)) {
        futureEvents.push(event);
      }
    });

    return futureEvents;
  }

  public getFilteredPastUserEvents(
    preFetchedUserEvents: UserEvent[],
  ): UserEvent[] {
    const pastEvents: UserEvent[] = [];

    preFetchedUserEvents.forEach((event) => {
      if (isUnixTimestampInPast(event.eventDate)) {
        pastEvents.push(event);
      }
    });

    return pastEvents;
  }

  public getEventTimeline(eventDate: number): Observable<EventTimeline> {
    return this.ecomCalendarApiService.getEventTimeline(eventDate);
  }

  public async updateEventDetails(
    eventId: string,
    eventDetails: EventDetails,
  ): Promise<{ updatedEvent: Event }> {
    return firstValueFrom(
      this.ecomEventApiService.updateEventDetails(eventId, eventDetails),
    );
  }
}
