import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { DealerUser, EcomDealerUserV2Types as v2 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomDealerUser_V2_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = 'api/2/dealerUser';

  constructor(private httpClient: HttpClient) {
    console.log('🚀 Initializing EcomDealerUser_V2_ApiService');
  }

  /*****************************************************
   *                      CREATE                       *
   *****************************************************/

  public createDealerUser(user: DealerUser): Observable<DealerUser> {
    const url = `${this.apiUrl}/create-user`;
    return this.httpClient.post<v2.CreateDealerUserSuccessResponse>(url, user, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  /****************************************************
   *                     READ                         *
   ****************************************************/

  public getDealerIdsByUserId(
    userId: string,
  ): Observable<{ dealerIds: string[] }> {
    const apiUrl = `${this.apiUrl}/dealerIds/${userId}`;
    return this.httpClient.get<v2.GetDealerIdsByUserIdSuccessResponse>(apiUrl, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getDealerUserByUserIdAndEmail(
    userId: string,
    email: string,
  ): Observable<DealerUser> {
    const url = `${this.apiUrl}/dealerUser/userId/${userId}/email/${email}`;

    return this.httpClient.get<v2.GetDealerUserByUserIdAndEmailSuccessResponse>(
      url,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public getUsersByDealerId(dealerId: string): Observable<DealerUser[]> {
    const url = `${this.apiUrl}/dealerId/${dealerId}`;
    return this.httpClient.get<v2.GetUsersByDealerIdSuccessResponse>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  /*****************************************************
   *                     UPDATE                        *
   *****************************************************/

  public disableDealerUser(firestoreId: string): Observable<void> {
    const url = `${this.apiUrl}/disable/${firestoreId}`;
    return this.httpClient.delete<v2.DisableDealerUserSuccessResponse>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public enableDealerUser(firestoreId: string): Observable<void> {
    const url = `${this.apiUrl}/enable/${firestoreId}`;
    return this.httpClient.post<v2.EnableDealerUserSuccessResponse>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public markEmailInviteSent(user: DealerUser): Observable<DealerUser> {
    const url = `${this.apiUrl}/email-invite/${user.documentId}`;
    return this.httpClient.put<DealerUser>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public updateDealerUserRole(
    id: string,
    dealerId: string,
    role: string,
  ): Observable<void> {
    const url = `${this.apiUrl}/id/${id}/dealerId/${dealerId}/role/${role}`;

    return this.httpClient.post<v2.UpdateDealerUserRoleSuccessResponse>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  /*****************************************************
   *                      DELETE                       *
   *****************************************************/

  public deleteDealerUser(firestoreId: string): Observable<void> {
    const url = `${this.apiUrl}/delete/${firestoreId}`;
    return this.httpClient.delete<v2.DeleteDealerUserSuccessResponse>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }
}
