import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { Categories, EcomStyle } from 'common-types';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
import { TransferStateService } from '../../services/transfer-state/transfer-state.service';
import { SEARCH_RESULTS_FOR_STYLES_PLP_KEY } from '../../transfer-state-keys/search-results-for-styles-plp.key';

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StylePlpResolver implements Resolve {
  private seoService = inject(SEOService);
  private search = inject(SearchService);
  private transferState = inject(TransferStateService);
  private categories = new Categories();
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  constructor() {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const category = route.paramMap.get('category');
    if (!category) {
      return; // maybe route to home???
    }
    const metaData: MetaInfo = {
      metaTitle: category ?? undefined,
      metaKeywords: undefined,
      metaDescription: undefined,
      noIndex: true,
    };

    switch (category) {
      case 'shirts':
        metaData.metaTitle = 'Dress Shirts';
        metaData.metaDescription =
          'Discover the epitome of elegance with our premium dress shirts. Impeccable craftsmanship, timeless styles, and superior comfort. Upgrade your wardrobe today!';
        metaData.noIndex = false;
        break;
      case 'ties':
        metaData.metaTitle = 'Ties and Bowties';
        metaData.metaDescription =
          'Elevate your style with our exquisite ties. Explore a collection of 400 colors and modern patterns for the perfect finishing touch. Shop now and redefine your look.';
        metaData.noIndex = false;
        break;
      case 'vests-and-cummerbunds':
        metaData.metaTitle = 'Vests and Cummerbunds';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Elevate your formal look with stylish vests and cummerbunds. Explore classic and contemporary designs to find the perfect accessory. Shop now and redefine your style!';
        break;
      case 'shoes-and-socks':
        metaData.metaTitle = 'Dress Shoes and Socks';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Step up your style game with our trendy collection of shoes and socks. Explore a variety of designs and materials for a fashionable and comfortable look. Shop now!';
        break;

      case 'cufflinks-and-studs':
        metaData.metaTitle = 'Cufflinks and Studs';
        metaData.noIndex = false;
        metaData.metaDescription =
          'The smallest details matter so enhance your formal attire with our elegant cufflinks and studs for the perfect finishing touch. Shop now to elevate your look!';
        break;

      case 'accessories':
        metaData.metaTitle = 'Formal Wear Accessories';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Complete your formal look with our exquisite suit and tuxedo accessories. Shop now for stylish ties, cufflinks, and pocket squares. Elevate your style!';
        break;
      default:
        metaData.noIndex = true;
        break;
    }

    const categoryItem = this.categories.byRoute(category);
    if (!categoryItem) {
      return false;
    }

    const { queryParams } = route;
    const isParameterizedRoute = Object.keys(queryParams).length > 0;

    const searchCriteria = this.search.getSearchCriteriaForStyles(
      categoryItem,
      [],
    );
    // just defining the search here.  This may or may not be called below.
    const search$ = this.search.style(searchCriteria).pipe(
      map((elasticStyles) => {
        const styles: EcomStyle[] = [];
        const recastedElasticStylesType: any = elasticStyles.hits.total;
        // To fix later
        const resultsCount = recastedElasticStylesType.value as number;
        if (elasticStyles.hits.hits.length > 0) {
          // this.resultSize = resultsCount;
          elasticStyles.hits.hits.forEach((style: any) => {
            styles.push(style._source as EcomStyle);
          });
        }
        return {
          styles,
          resultsCount,
          categoryItem,
        };
      }),
      catchError((error) => {
        console.error('Error in StylePlpResolver', error);
        return of({
          styles: [],
          resultsCount: 0,
          categoryItem,
        });
      }),
    );

    console.log('Search Criteria:', searchCriteria);

    const searchResultsTransferState = this.transferState.get(
      SEARCH_RESULTS_FOR_STYLES_PLP_KEY,
      null,
    );

    // console.log(
    //   'Search Results Transfer State resultsCount:',
    //   searchResultsTransferState?.resultsCount,
    // );

    /** The source of the searchResults changes depending on context (server/browser),
     * TransferState availability (might be disabled), and whether the route has query params.
     */
    const getSearchResults = async () => {
      if (this.isServer) {
        console.log('Server StylePlpResolver');

        // We won't handle queryParams on the server, so don't call the api
        if (isParameterizedRoute && this.isServer) {
          console.log(
            'Query Parameterized Route on the server, skipping API calls',
          );
          return {
            styles: [],
            resultsCount: 0,
            categoryItem,
          };
        }
        console.log('Not a parameterized route, calling API');
        return firstValueFrom(search$);
      }

      // only return transferState on the browser
      console.log('Browser StylePlpResolver');
      if (!!searchResultsTransferState) {
        console.log('TransferState Found.  Using TransferState');
        return searchResultsTransferState;
      }
      console.log('No TransferState Found');
      // We need filter categories to handle query params, which has to be handled in the component,
      // so don't bother calling the search api here.
      if (isParameterizedRoute) {
        console.log(
          'Query Parameterized Route on the browser, skipping search API call in resolver (need filter categories first)',
        );
        return {
          styles: [],
          resultsCount: 0,
          categoryItem,
        };
      }
      console.log('Not a parameterized route, calling API');
      return firstValueFrom(search$);
    };

    const searchResults = await getSearchResults();
    // only set TransferState if we actually have some styles on the server
    if (this.isServer && searchResults.styles.length > 0) {
      console.log(
        'Search Results ',
        'resultsCount: ',
        searchResults.resultsCount,
        ' numStyles: ',
        searchResults.styles.length,
      );

      this.transferState.set(SEARCH_RESULTS_FOR_STYLES_PLP_KEY, searchResults);
    }

    // Check if it is a parameterized route (we do not index paramterized routes)

    if (isParameterizedRoute) {
      this.seoService.setNoIndexTag(true);
      /**
       * If noIndex is ever true, canonical should be removed
       * Default remove canonical.
       *  */
      this.seoService.setCanonicalTag(state.url, false);
    } else {
      this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
      this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
    }
    this.seoService.setMetaTags(metaData);
    // route.data = { seoParams: metaData };

    return searchResults;
  }
}
