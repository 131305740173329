import { ElasticResponseEcomEnsemblePLP } from 'common-types';

export const elasticPLPResults: any = {
  totalHits: 35,
  ensembles: [
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 1,
      companionEnsembleCode: '921-P1',
      companionEnsemblePrice: 479,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '921',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '921',
      groupRank: 1,
      id: '921',
      images: [
        {
          alt: 'Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie',
          src: 'wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg',
        },
      ],
      keywords: ['rent', 'buy', 'stretch'],
      metaDescription: '',
      platformEnsembleFirestoreDocumentId: 'vS0KqygjGJ9VhMZMrveq',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Black Legacy Performance Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1688965200000,
      designer: 'Michael Kors',
      colorHexCode: '#000000',
      hasPurchaseOption: true,
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 2,
      companionEnsembleCode: '421-p1',
      companionEnsemblePrice: 479,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '421',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '421',
      groupRank: 1,
      id: '421',
      images: [
        {
          alt: 'man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie',
          src: 'wedding-suit-black-performance-stretch-michael-kors-421.jpg',
        },
      ],
      keywords: ['stretch', 'rent', 'buy'],
      occasion: 'Wedding',
      platformEnsembleFirestoreDocumentId: 'luVOxbI9rU1QPDwpfhfY',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Black Performance Wedding Suit',
      webDiscontinuedDate: 0,
      webReleaseDate: 1688101200000,
      whoIsPictured: 'Individual',
      designer: 'Michael Kors',
      colorFamily: 'Black',
      colorHexCode: '#000000',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      id: '272',
      ensembleCode: '272',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '272',
      title: 'Tan Performance Wedding Suit',
      description: '',
      price: 190,
      keywords: ['rent', 'buy', 'khaki', 'brown'],
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '272-p1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-tan-performance-stretch-michael-kors-272.jpg',
          alt: 'man wearing Michael Kors Tan Performance Stretch suit with matching vest and teal windsor tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'ZS1JsOZpVqZm0DU27mTx',
      webReleaseDate: 1708408800000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 3,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Tan/Chocolate',
      colorHexCode: '#EEE9DF',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      id: '350',
      ensembleCode: '350',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '350',
      title: 'Medium Grey Performance Wedding Suit',
      description: '',
      price: 190,
      keywords: ['gray', 'suit', 'rent', 'buy'],
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '350-p1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-medium-grey-performance-stretch-michael-kors-350-1.jpg',
          alt: 'Man wearing Michael Kors Medium Grey suit and matching vest and bow tie standing outside in front of a trellis',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'pYro9nqBcEwNlYuldJGe',
      webReleaseDate: 1688965200000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 3,
      realPeople: false,
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Grey/Silver',
      colorHexCode: '#888888',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      id: '302',
      ensembleCode: '302',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '302',
      title: 'Blue Performance Wedding Suit',
      description: '',
      price: 190,
      keywords: ['stretch', 'rent buy'],
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '302-P1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-blue-performance-stretch-michael-kors-302-6.jpg',
          alt: 'man in Michael Kors Blue Performance suit and Foundations Blue vest and tie standing in front of a staircase',
        },
      ],
      dealerUse: true,
      styles: [],
      platformEnsembleFirestoreDocumentId: 'd2czVHhbafHpYhQOY6Se',
      styleCodes: [],
      webReleaseDate: 1688101200000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 5,
      realPeople: false,
      occasion: 'Wedding',
      whoIsPictured: 'Individual',
      groupRank: 1,
      designer: 'Michael Kors',
      colorFamily: 'Blue',
      colorHexCode: '#273154',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 6,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '981',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '981',
      groupRank: 1,
      id: '981',
      images: [
        {
          alt: 'man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie',
          src: 'wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg',
        },
      ],
      keywords: ['stretch', 'rent'],
      occasion: '',
      platformEnsembleFirestoreDocumentId: 'oK48rACO7kTTF0bKHq5z',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Black Force Performance Stretch Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1695877200000,
      whoIsPictured: '',
      designer: 'Michael Kors',
      colorFamily: 'Black',
      colorHexCode: '#000000',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '371',
      ensembleCode: '371',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '371',
      title: 'Slim Fit Navy Sterling Wedding Suit',
      description: '',
      price: 190,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-suit-navy-michael-kors-sterling-371-1.jpg',
          alt: 'Man leaning on a stool in a Wedding Suit Navy Michael Kors Sterling with matching Fullback Vest and Black Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: '2bm3DfGSmiMOOjwXPgmo',
      webReleaseDate: 1688101200000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 7,
      realPeople: false,
      occasion: 'Wedding',
      whoIsPictured: 'Individual',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Blue',
      colorHexCode: '#25334D',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      keywords: [],
      id: '372',
      ensembleCode: '372',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '372',
      title: 'Navy Sterling Wedding Suit',
      description: '',
      price: 190,
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '372-p1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-navy-michael-kors-sterling-372-3.jpg',
          alt: 'Couple laughing after the wedding in a Wedding Suit Navy Michael Kors Sterling with Black Windsor Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'PGNHM7QWi4MyVzlMM1zB',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 8,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Blue',
      colorHexCode: '#25334D',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      keywords: [],
      id: '391',
      ensembleCode: '391',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '391',
      title: 'Slim Fit Steel Grey Sterling Wedding Suit',
      description: '',
      price: 190,
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-suit-steel-grey-michael-kors-sterling-391-1.jpg',
          alt: 'buttoning jacket in a Wedding Suit Steel Grey Michael Kors Sterling with matching Fullback Vest and Expressions White Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'VvAupOJNEwpdrV0VqKxV',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 9,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Grey/Silver',
      colorHexCode: '#666666',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '392',
      ensembleCode: '392',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '392',
      title: 'Steel Grey Sterling Wedding Suit',
      description: '',
      price: 190,
      keywords: ['gray', 'steel gray', 'rent', 'buy'],
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '392-P1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-steel-grey-michael-kors-sterling-392-4_1.jpg',
          alt: 'Wedding Suit Steel Grey Michael Kors Sterling with matching Fullback Vest',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'W58RYL8ARym4TpLEE5ST',
      webReleaseDate: 1688533200000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 10,
      realPeople: false,
      occasion: 'Wedding',
      whoIsPictured: 'Group',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Grey/Silver',
      colorHexCode: '#666666',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      id: '991',
      ensembleCode: '991',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '991',
      title: 'Slim Fit Black Berkeley Tuxedo',
      description: '',
      price: 190,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-tuxedo-black-michael-kors-berkeley-991-1.jpg',
          alt: 'Wedding Tuxedo Black Michael Kors Berkeley with Black Satin Fullback Vest and Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: '1PeC7DfsMLLWdIqo2Vhe',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 13,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Black',
      colorHexCode: '#000000',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '990',
      ensembleCode: '990',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '990',
      title: 'Black Berkeley Tuxedo',
      description: '',
      price: 190,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-tuxedo-black-michael-kors-berkeley-990-1.jpg',
          alt: 'Guy holding his wife close to his Black Michael Kors Berkeley Wedding Tuxedo',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: '8wg1gVlL9kXbwiiCdXsT',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 14,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Michael Kors',
      colorFamily: 'Black',
      colorHexCode: '#000000',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '202',
      ensembleCode: '202',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '202',
      title: 'Sand Brunswick Suit',
      description: '',
      price: 190,
      keywords: ['tan', 'khaki', 'stretch', 'rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-suit-sand-allure-men-brunswick-202-1.jpg',
          alt: 'Newly wed couple standing outside wearing a Wedding Suit Sand Allure Men Brunswick with Matching Fullback Vest',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: '08RBVCNi1Owwl3j7LZ8b',
      webReleaseDate: 1688101200000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 15,
      realPeople: false,
      occasion: 'Wedding',
      whoIsPictured: 'Couple',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Allure Men',
      colorFamily: 'Tan/Chocolate',
      colorHexCode: '#BCAFA5',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '262',
      ensembleCode: '262',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '262',
      title: 'Heather Grey Clayton Suit',
      description: '',
      price: 190,
      keywords: ['gray', 'heather gray', 'rent', 'buy'],
      categories: ['SuitsAndTuxedos'],
      companionEnsembleCode: '262-P1',
      companionEnsemblePrice: 479,
      images: [
        {
          src: 'wedding-suit-heather-grey-allure-men-clayton-262-1.jpg',
          alt: 'Wedding Suit Heather Grey Allure Men Clayton with Matching Fullback Vest and Navy Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: '8TKpQZ2Sqs5gJChQenLS',
      webReleaseDate: 1688619600000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 16,
      realPeople: false,
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Allure Men',
      colorFamily: 'Grey/Silver',
      colorHexCode: '#A0A0A0',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      id: '211',
      ensembleCode: '211',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '211',
      title: 'Cobalt Blue Tribeca Tuxedo',
      description: '',
      price: 190,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-tuxedo-blue-ike-behar-tribeca-211-1.jpg',
          alt: 'Wedding Tuxedo Blue Ike Behar Tribeca with Black Expressions Fullback Vest and Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'r8qcaA7DEJ3Ft0vQ6LRR',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 17,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Ike Behar',
      colorFamily: 'Blue',
      colorHexCode: '#2d406f',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '221',
      ensembleCode: '221',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '221',
      title: 'Indigo Blue Lane Tuxedo',
      description: '',
      price: 190,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-tuxedo-indigo-blue-ike-behar-lane-221-1.jpg',
          alt: 'Wedding Tuxedo Indigo Blue Ike Behar Lane with Champagne Bow Tie and Fullback Vest',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'byIecnp13avPhTa2PtNX',
      webReleaseDate: 1698728400000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 18,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Ike Behar',
      colorFamily: 'Blue',
      colorHexCode: '#2E3F5D',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '201',
      ensembleCode: '201',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '201',
      title: 'Burgundy Empire Tuxedo',
      description: '',
      price: 190,
      keywords: ['red', 'holiday', 'burgandy', 'rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-tuxedo-burgundy-kenneth-cole-empire-201-1.jpg',
          alt: 'Wedding Tuxedo Burgundy Kenneth Cole Empire with Black Pants and Black Satin Bow Tie',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'OsmSUlU3B8DCBMoDgMTt',
      webReleaseDate: 1692075600000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 19,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styleCodes: [],
      styles: [],
      designer: 'Kenneth Cole',
      colorFamily: 'Red/Burgundy',
      colorHexCode: '#472B2E',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 21,
      companionEnsembleCode: '172-P1',
      companionEnsemblePrice: 349,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '172',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '172',
      groupRank: 1,
      id: '172',
      images: [
        {
          alt: 'man wearing White/Silver Paisley Aries Tuxedo Coat with black pants, silver tie and pocket square, and silver sparkle shoes',
          src: 'prom-tuxedo-white-silver-paisley-mark-of-distinction-aries-172.jpg',
        },
      ],
      keywords: ['rent', 'buy'],
      occasion: '',
      platformEnsembleFirestoreDocumentId: 'M0RVBk74H5eqCaaTmDyG',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'White/Silver Paisley Aries Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1708322400000,
      whoIsPictured: '',
      designer: 'Mark of Distinction',
      colorFamily: 'White/Ivory',
      colorHexCode: '#FFFFFF',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 21,
      companionEnsembleCode: '192-P1',
      companionEnsemblePrice: 349,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '192',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '192',
      groupRank: 1,
      id: '192',
      images: [
        {
          alt: 'Senior at the prom in a Prom Tuxedo Granite Paisley Mark of Distinction Aries',
          src: 'prom-tuxedo-granite-paisley-mark-of-distinction-aries-192-1.jpg',
        },
      ],
      keywords: [
        'black',
        'dinner jacket',
        'holiday',
        'reception',
        'rent',
        'buy',
      ],
      occasion: '',
      platformEnsembleFirestoreDocumentId: 'uBCPUVzWMr7pRoOdiOuv',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Granite Paisley Aries Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1695877200000,
      whoIsPictured: '',
      designer: 'Mark of Distinction',
      colorFamily: 'Black',
      colorHexCode: '#535353',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 22,
      companionEnsembleCode: '182-P1',
      companionEnsemblePrice: 349,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '182',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '182',
      groupRank: 1,
      id: '182',
      images: [
        {
          alt: 'man wearing black paisley jacket with ultra slim stretch trousers and a black bow tie',
          src: 'prom-tuxedo-black-paisley-mark-of-distinction-aries-182-23-1.jpg',
        },
      ],
      keywords: ['rent', 'buy'],
      occasion: '',
      platformEnsembleFirestoreDocumentId: 'ruKtdpkMgucgc4gIY4Rp',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Black Paisley Aries Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1698728400000,
      whoIsPictured: '',
      designer: 'Mark of Distinction',
      colorFamily: 'Black',
      colorHexCode: '#000000',
      hasPurchaseOption: true,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 23,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '122',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '122',
      groupRank: 1,
      id: '122',
      images: [
        {
          alt: 'Going into prom in the Prom Tuxedo Apple Red Paisley Mark of Distinction Aries',
          src: 'prom-tuxedo-apple-red-paisley-mark-of-distinction-aries-122-1.jpg',
        },
      ],
      keywords: ['claret', 'dinner jacket', 'holiday', 'rent'],
      platformEnsembleFirestoreDocumentId: 'yoUp11KPBYHZd5RUuMQd',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Apple Red Paisley Aries Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1688360400000,
      designer: 'Mark of Distinction',
      colorFamily: 'Red/Burgundy',
      colorHexCode: '#730000',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      categories: ['SuitsAndTuxedos'],
      categoryRank: 24,
      dealerUse: true,
      description: '',
      displayOnPLP: true,
      ensembleCode: '132',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '132',
      groupRank: 1,
      id: '132',
      images: [
        {
          alt: 'A senior at the prom in his Prom Tuxedo Cobalt Blue Paisley Mark of Distinction Aries',
          src: 'prom-tuxedo-cobalt-blue-paisley-mark-of-distinction-aries-132-1.jpg',
        },
      ],
      keywords: ['rent'],
      occasion: '',
      platformEnsembleFirestoreDocumentId: 'xiY4FqfpsDFpCG0TYxLC',
      price: 190,
      realPeople: false,
      styleCodes: [],
      styles: [],
      title: 'Cobalt Blue Paisley Aries Tuxedo',
      webDiscontinuedDate: 0,
      webReleaseDate: 1698728400000,
      whoIsPictured: '',
      designer: 'Mark of Distinction',
      colorFamily: 'Blue',
      colorHexCode: '#2d406f',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '252',
      ensembleCode: '252',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '252',
      title: 'Tan Havana Suit',
      description: '',
      price: 180,
      keywords: ['brown', 'khaki', 'rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'wedding-suit-tan-havana-252-1.jpg',
          alt: 'Bride and groom in the Tan Havana Wedding Suit standing on a boat',
        },
      ],
      dealerUse: true,
      platformEnsembleFirestoreDocumentId: 'nz80DhCTacCSCR8Jamtu',
      styleCodes: [],
      webReleaseDate: 1698814800000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 25,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      styles: [],
      designer: 'Lord West',
      colorFamily: 'Tan/Chocolate',
      colorHexCode: '#EEE9DF',
      hasPurchaseOption: false,
      metaDescription: '',
    },
    {
      id: '822',
      ensembleCode: '822',
      ensembleGroup: 'Rental',
      ensembleGroupCode: '822',
      title: 'Black Cyprus Tuxedo',
      description: '',
      price: 180,
      keywords: ['rent'],
      categories: ['SuitsAndTuxedos'],
      images: [
        {
          src: 'stephen-geoffrey-cyprus-tuxedo.jpg',
          alt: 'A prom guy wearing the Black Cyprus Tuxedo, white shirt, and begonia colored vest and tie',
        },
      ],
      dealerUse: true,
      styles: [],
      platformEnsembleFirestoreDocumentId: 'xfncYPJ666vrXAyo8uYy',
      styleCodes: [],
      webReleaseDate: 1698814800000,
      webDiscontinuedDate: 0,
      displayOnPLP: true,
      categoryRank: 28,
      realPeople: false,
      occasion: '',
      whoIsPictured: '',
      groupRank: 1,
      designer: 'Stephen Geoffrey',
      colorHexCode: '#000000',
      hasPurchaseOption: false,
      metaDescription: '',
    },
  ],
};
