import { Injectable, OnDestroy, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ECOM_EVENT_API_SERVICES,
  ECOM_INVITE_API_SERVICES,
  ECOM_USER_EVENT_API_SERVICES,
} from '@jfw-library/ecommerce/api-services';
import { AuthService, EventService } from '@jfw-library/ecommerce/core';
import {
  isUnixTimestampInPast,
  isUserOrganizerOrAdminForEvent,
} from 'business-logic';
import {
  Event,
  InviteStatus,
  LandingPageBaseResponse,
  UserEvent,
} from 'common-types';
import dayjs from 'dayjs';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  catchError,
  firstValueFrom,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class EcomEventService implements OnDestroy {
  public selectedEvent: Event | null = null;
  private subscription = new Subscription();
  // private gettingUserEvents = false;
  private userEvents: UserEvent[] = [];

  public selectedEvent$ = new BehaviorSubject<Event>({} as Event);
  public eventSaveError$ = new BehaviorSubject<boolean>(false);
  public getStartedEvent$ = new BehaviorSubject<Event | null>(null);
  public canProceed$ = new Subject<boolean>();
  public nextClicked$ = new Subject<boolean>();
  public forceNextStep$ = new Subject<void>();
  public validateStep$ = new Subject<void>();

  private ecomEventApiService = inject(ECOM_EVENT_API_SERVICES.v8);
  private ecomUserEventApiService = inject(ECOM_USER_EVENT_API_SERVICES.v4);
  private ecomInviteApiService = inject(ECOM_INVITE_API_SERVICES.v4);
  private authService = inject(AuthService);
  private environmentService = inject(EnvironmentService);
  private environment = this.environmentService.getEnvironment();
  private MAX_EVENT_LOCAL_STORAGE_AGE =
    this.environment.max_event_local_storage_age;

  constructor(private eventService: EventService) {
    console.log(
      'EcomEventService is using Event API version: ',
      this.ecomEventApiService.apiUrl,
    );
    console.log(
      `EcomEventService is running in ${this.environment.envVersion}`,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private isEventLocalStorageOutdated(
    varName: string = 'events_updated',
  ): boolean {
    let dateDiff = 999999999; // set default diff to extreme

    // If exists in local storage, calculate age
    if (localStorage.getItem(varName) !== null) {
      const updateDate = dayjs(localStorage.getItem(varName));
      const currentDate = dayjs(Date.now());

      dateDiff = currentDate.diff(updateDate);
    }

    return dateDiff > this.MAX_EVENT_LOCAL_STORAGE_AGE;
  }

  /// -- START UserEvents

  public initUserEvents(userEvents: UserEvent[]): void {
    this.setAllUserEventsLocalStorage(userEvents);
  }

  private setAllUserEventsLocalStorage(userEvents: UserEvent[]) {
    localStorage.setItem('user_events_updated', dayjs(Date.now()).toString());
    localStorage.setItem('user_events', JSON.stringify(userEvents));
  }

  private clearAllUserEventsLocalStorage() {
    localStorage.removeItem('user_events_updated');
    localStorage.removeItem('user_events');
  }

  // Re-factored to have only one getUserEvents function during EXT-2270.
  // Removed getUserEventsAsync and getUserEventsPromise.
  // This was the old version.
  // private async getUserEvents(forceRefresh: boolean = false): Promise<UserEvent[]> {

  //   const userId: string | undefined = this.user?.uid;
  //   // Check if local storage is expired
  //   if (localStorage.getItem('user_events') !== null) {
  //     this.userEvents = JSON.parse(localStorage.getItem('user_events') || '[]');
  //   }

  //   if (
  //     forceRefresh ||
  //     (this.isEventLocalStorageOutdated('user_events_updated') &&
  //       !this.gettingUserEvents)
  //   ) {
  //     if (!userId) {
  //       console.log("Can't refresh UserEvents.  userId is undefined.");
  //       return this.userEvents;
  //     }
  //     this.gettingUserEvents = true;

  //     console.log('Get UserEvents Promise');
  //     try {
  //       const userEvents = await firstValueFrom(this.ecomUserEventApiService.getUserEventsForCurrentUser());
  //       console.log('Returning UserEvents');
  //       this.gettingUserEvents = false;
  //       this.userEvents = userEvents;
  //       this.setAllUserEventsLocalStorage(userEvents);
  //       return this.userEvents;
  //     } catch (error) {
  //       console.error('Error getting UserEvents', error);
  //       return this.userEvents;
  //     }
  //   }
  //   return this.userEvents;
  // }

  public async getUserEvents(
    forceRefresh: boolean = false,
  ): Promise<UserEvent[]> {
    const userId: string | undefined = (await this.authService.currentUser)
      ?.uid;
    // Check if local storage is expired
    if (localStorage.getItem('user_events') !== null) {
      this.userEvents = JSON.parse(localStorage.getItem('user_events') || '[]');
    }

    if (
      !forceRefresh &&
      !this.isEventLocalStorageOutdated('user_events_updated')
    ) {
      console.log(
        'UserEvents not outdated.  Returning UserEvents from local storage.',
      );
      return this.userEvents;
    }

    if (!userId) {
      console.log("Can't get UserEvents.  userId is undefined.");
      return this.userEvents;
    }

    // console.log('Getting UserEvents...');
    // this.gettingUserEvents = true;

    try {
      const userEvents = await firstValueFrom(
        this.ecomUserEventApiService.getUserEventsForCurrentUser(),
      );
      console.log('Returning UserEvents');
      this.userEvents = userEvents;
      this.setAllUserEventsLocalStorage(userEvents);
      // this.gettingUserEvents = false;
      return this.userEvents;
    } catch (error) {
      console.error('Error getting UserEvents', error);
      // this.gettingUserEvents = false;
      return this.userEvents;
    }
  }

  public getFilteredFutureUserEvents(
    preFetchedUserEvents: UserEvent[],
  ): UserEvent[] {
    const futureEvents: UserEvent[] = [];

    preFetchedUserEvents.forEach((event) => {
      if (!isUnixTimestampInPast(event.eventDate)) {
        futureEvents.push(event);
      }
    });

    return futureEvents;
  }

  public getFilteredPastUserEvents(
    preFetchedUserEvents: UserEvent[],
  ): UserEvent[] {
    const pastEvents: UserEvent[] = [];

    preFetchedUserEvents.forEach((event) => {
      if (isUnixTimestampInPast(event.eventDate)) {
        pastEvents.push(event);
      }
    });

    return pastEvents;
  }

  /** First checks if user is an admin/organizer,
   * then deletes the event, then deletes all UserEvents associated with that event's id. */
  public async deleteEventAndUserEvents(eventToDelete: Event) {
    const eventId = eventToDelete.id;
    const user = await this.authService.currentUser;
    const isAdmin = isUserOrganizerOrAdminForEvent(eventToDelete, user);
    if (!isAdmin) {
      alert('You are not authorized to delete this event.');
      throw Error('User not authorized to delete event');
    }

    return firstValueFrom(
      this.ecomEventApiService.deleteEvent(eventId).pipe(
        catchError((err) => {
          console.error('Error deleting the event', err);
          throw err;
        }),
        switchMap((result) =>
          from(this.getUserEvents()).pipe(
            catchError((err) => {
              console.error('Error refreshing userEvents', err);
              console.log('Clearing all user events from local storage');
              this.clearAllUserEventsLocalStorage(); // clear the user events from local storage because they are now outdated
              return of(undefined);
            }),
          ),
        ),
      ),
    );

    // return from(this.getUserEvents()).pipe( // STEP 1: get the current user's events
    //   map((userEvents) => { // check if the event exists in the user's events
    //     const eventIndex = userEvents.findIndex((userEvent) => {
    //       return eventId === userEvent.id;
    //     });

    //     if (eventIndex < 0) { // user does not have a UserEvent for this event.
    //       throw Error('user does not have a UserEvent for this event.');
    //     }
    //     return userEvents;
    //   }),
    //   catchError((err) => {
    //     console.error('Error finding the event to delete', err);
    //     throw err;
    //   }),
    //   switchMap((userEvents) => this.ecomEventApiService.deleteEventById(eventId).pipe( // STEP 2: Delete the event
    //       map(() => userEvents), // pass the userEvents to the next step
    //       catchError((err) => {
    //         console.error('Error deleting event', err);
    //         throw err;
    //       }),
    //   )
    //   ),
    //   switchMap((userEvents) => this.ecomUserEventApiService.deleteAllUserEventsByEventId(eventId).pipe( // STEP 3: delete the user events
    //     map(() => userEvents), // pass the userEvents to the next step
    //     catchError((err) => {
    //       console.error('Error deleting user event', err);
    //       throw err;
    //     }),
    //   )), // delete the user event
    //   tap((userEvents) => { // STEP 4: update the user's events in local storage
    //     const newUserEvents = userEvents.filter((userEvent) => { // remove the deleted event from the user's events
    //       return eventId !== userEvent.id;
    //     });
    //     this.setAllUserEventsLocalStorage(newUserEvents);
    //   }),
    //   catchError((err) => {
    //     console.error('Error deleting user event', err);
    //     throw err;
    //   })
    // );
  }

  public async landingPageVerifyMemberV2(
    eventId: string,
    memberEmail: string,
  ): Promise<LandingPageBaseResponse> {
    let landingPageResponse: LandingPageBaseResponse = {
      badUrl: true,
      inviteAccepted: false,
      userEventId: undefined,
      eventData: undefined,
    };
    const userId = (await this.authService.currentUser)?.uid;
    if (userId) {
      try {
        const inviteStatus = await firstValueFrom(
          this.ecomInviteApiService.verifyEventInvite(eventId, memberEmail),
        );
        console.log('EVENT INVITE STATUS', inviteStatus);
        if (this.verifyInviteStatus(inviteStatus)) {
          landingPageResponse.badUrl = false;
          landingPageResponse.inviteAccepted = true;
          landingPageResponse.userEventId = inviteStatus.userEventId;
          landingPageResponse.eventData = inviteStatus.eventData;
          // landingPageResponse.inviteAccepted = true;
          // landingPageResponse.userEventId = eventResponse.userEventId;
          // landingPageResponse.eventData = eventResponse.eventData;
        }
      } catch (err) {
        console.log('Error fetching event', err);
      }
    }
    return landingPageResponse;
  }

  // TODO: refactor to make user a required param for this function, instead of a class property and also use observable instead of all the nested promises and async/awaits
  private verifyInviteStatus(inviteStatus: InviteStatus | 'error'): boolean {
    // If inviteStatus is undefined or has an error, return false
    if (
      inviteStatus === undefined ||
      inviteStatus === 'error' ||
      inviteStatus.errorEncountered
    ) {
      return false;
    }
    if (
      inviteStatus.success &&
      inviteStatus?.eventExists &&
      inviteStatus?.userEmailMatchesInvite &&
      (inviteStatus?.userIsMember ||
        inviteStatus?.userIsOrganizer ||
        inviteStatus?.userIsAdmin) &&
      inviteStatus?.userEventExists
    ) {
      return true;
    }
    return false;
  }

  public async getEventById(id: string): Promise<Event> {
    return firstValueFrom(
      this.ecomEventApiService.getEventById(id).pipe(
        map(({ event }) => {
          return event;
        }),
      ),
    );
  }

  /**
   * Creates an event from Ecom, stores the created event's eventId in LocalStorage, sets the selectedEvent in EventService, closes the dialog (if provided), and routes to the first step.
   * @param event The event to be created
   * @param dialogRef The dialog reference to be closed after the event is created (e.g., MatDialogRef<GetStartedModalComponent>)
   * @returns
   */
  public async createEvent(
    event: Event,
    dialogRef?: MatDialogRef<unknown>,
    userId: string | undefined = undefined,
  ): Promise<Event> {
    const userIdForEvent = (await this.authService.currentUser)?.uid ?? userId;
    if (!userIdForEvent) {
      console.error('User not logged in');
      throw new Error('User not logged in');
    }

    // Get the customLinkId stored in local storage (which should be the dealerId)
    // This is what identifies an event as Custom Link vs. Organic
    // If it exists, it will be treated as a Custom Link event.
    const dealerId = localStorage.getItem('customLinkId');

    if (dealerId) {
      console.log('customLinkId found:', dealerId, ' -- CUSTOM_LINK event');
    } else {
      console.log('No customLinkId found -- ORGANIC event');
    }

    const eventToCreate: Event = {
      ...event,
      createdByUserId: userIdForEvent, // This is used to verify on the back end whether the createdByUserId in the payload matches the actual user (according to their Auth token)
      ...(dealerId ? { dealerId } : undefined), // If dealerId exists, add it to the event.
    };

    return firstValueFrom(
      this.ecomEventApiService
        .createEventFromEcom({ event: eventToCreate })
        .pipe(
          switchMap(({ newEvent }) =>
            this.eventService.setSelectedEvent(
              newEvent.id,
              'EcomEventService -- createEvent',
            ),
          ),
          tap(async (newEvent) => {
            // if (newEvent) {
            // newEvent.id = newEvent.id;
            // newEvent.lastUpdated = newEvent.lastUpdated;
            localStorage.removeItem('checkout_state');

            if (dialogRef) {
              console.log('Closing dialog');
              dialogRef.close();
            }

            this.getStartedEvent$.next(null);
            console.log('routing to first step');
            this.eventService.routeToFirstStep();
          }),
        ),
    );
  }
}
