import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService, SearchService } from '@jfw-library/ecommerce/core';
import {
  canOnlyBuyStyle,
  canOnlyRentStyle,
  canRentAndBuyStyle,
  getEnsembleBuyPrice,
  getEnsembleRentPrice,
  getImage,
  getImageKitImage,
  getStyleBuyPrice,
  getStyleRentPrice,
  hasDisplayPrice,
} from 'business-logic';
import {
  Categories,
  CategoryItem,
  EcomEnsemble,
  EcomStyle,
  FilterCategory,
  FilterSelect,
  GlobalSearchPaginatorOptions,
  Image,
  StyleGroup,
} from 'common-types';
import { Observable, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-search-listing-page',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchListingPageComponent implements OnInit, OnDestroy {
  ensembles: EcomEnsemble[] | null = null;
  styles: EcomStyle[] | null = null;
  subscription = new Subscription();
  styleGroup = StyleGroup.Unassigned;
  categoryItem: CategoryItem | undefined;
  searchText: string | undefined;
  filterCategories: FilterCategory[] = [];
  categories = new Categories();
  emptyResults = false;

  // Paginator
  resultSize = 0;
  pageSize = 24;
  pageIndex = 0;

  ensemblesSize = 0;
  rentalStylesSize = 0;
  purchaseStylesSize = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  // Paginator
  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.route.params, this.route.queryParams]).subscribe({
        next: ([params, queryParams]) => {
          this.paramsChange(params, queryParams);
          this.getProducts();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  generateRangeMap(): Array<string> {
    let rangeMap = [];
    let ensembles = 0;
    let rentalStyles = 0;
    let purchaseStyles = 0;
    let totalProductCount =
      this.ensemblesSize + this.rentalStylesSize + this.purchaseStylesSize;
    for (let i = 0; i < totalProductCount; i++) {
      if (ensembles < this.ensemblesSize) {
        rangeMap.push('ensembles');
        ensembles++;
      } else if (rentalStyles < this.rentalStylesSize) {
        rangeMap.push('rentalStyles');
        rentalStyles++;
      } else if (purchaseStyles < this.purchaseStylesSize) {
        rangeMap.push('purchaseStyles');
        purchaseStyles++;
      }
    }
    return rangeMap;
  }

  public async change(event: PageEvent) {
    let { pageSize, pageIndex } = event;
    let { ensemblesSize, rentalStylesSize, purchaseStylesSize } = this;

    let totalProductCount =
      this.ensemblesSize + this.rentalStylesSize + this.purchaseStylesSize;

    let ensemblesRange = [0, ensemblesSize - 1];
    let rentalStylesRange = [
      ensemblesSize,
      ensemblesSize + rentalStylesSize - 1,
    ];
    let purchaseStylesRange = [
      ensemblesSize + rentalStylesSize,
      totalProductCount,
    ];

    if (ensemblesSize === 0) {
      ensemblesRange = [-1, -1];
    }
    if (rentalStylesSize === 0) {
      rentalStylesRange = [-1, -1];
    }
    if (purchaseStylesSize === 0) {
      purchaseStylesRange = [-1, -1];
    }

    let startingPoint = pageSize * pageIndex;
    let endingPoint = pageSize * pageIndex + pageSize;

    let rentalStylesQueryFrom = 0;
    let rentalStylesQuerySize = 0;

    let purchaseStylesQueryFrom = 0;
    let purchaseStylesQuerySize = 0;

    let rangeMap = this.generateRangeMap();
    rangeMap = rangeMap.slice(startingPoint, endingPoint);
    let ensembleCount = rangeMap.filter((type) => type === 'ensembles').length;
    let rentalStylesCount = rangeMap.filter(
      (type) => type === 'rentalStyles',
    ).length;
    let purchaseStylesCount = rangeMap.filter(
      (type) => type === 'purchaseStyles',
    ).length;

    let ensemblesQueryFrom = startingPoint;
    let ensemblesQuerySize = ensembleCount;

    if (
      endingPoint >= rentalStylesRange[0] &&
      startingPoint <= ensemblesRange[1]
    ) {
      rentalStylesQueryFrom = 0;
      rentalStylesQuerySize = rentalStylesCount;
    } else if (startingPoint >= ensemblesRange[1] && rentalStylesCount) {
      rentalStylesQueryFrom = startingPoint - ensemblesSize;
      rentalStylesQuerySize = pageSize;
    }

    if (
      endingPoint >= purchaseStylesRange[0] &&
      startingPoint <= rentalStylesRange[1] &&
      purchaseStylesCount
    ) {
      purchaseStylesQueryFrom = 0;
      purchaseStylesQuerySize = purchaseStylesCount;
    } else if (startingPoint >= rentalStylesRange[1] && purchaseStylesCount) {
      purchaseStylesQueryFrom =
        startingPoint - ensemblesSize - rentalStylesSize;
      purchaseStylesQuerySize = purchaseStylesCount;
    }

    if (ensemblesRange.includes(-1)) {
      ensemblesQueryFrom = 0;
      ensemblesQuerySize = 0;
    }
    if (rentalStylesRange.includes(-1)) {
      rentalStylesQueryFrom = 0;
      rentalStylesQuerySize = 0;
    }
    if (purchaseStylesRange.includes(-1)) {
      purchaseStylesQueryFrom = 0;
      purchaseStylesQuerySize = 0;
    }

    let paginatorOptions: GlobalSearchPaginatorOptions = {
      previousPageIndex: event.previousPageIndex ?? undefined,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      length: event.length,

      ensemblesQueryFrom: ensemblesQueryFrom,
      ensemblesQuerySize: ensemblesQuerySize,

      rentalStylesQueryFrom: rentalStylesQueryFrom,
      rentalStylesQuerySize: rentalStylesQuerySize,

      purchaseStylesQueryFrom: purchaseStylesQueryFrom,
      purchaseStylesQuerySize: purchaseStylesQuerySize,
    };

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await firstValueFrom(
      this.search
        .globalProductSearch(this.searchText!, paginatorOptions)
        .pipe(
          map((results: any) => {
            let styles: EcomStyle[] = [];
            let ensembles: EcomEnsemble[] = [];
            if (results.responses.length) {
              ensembles = [...results.responses[0].hits.hits];
              styles = [
                ...results.responses[1].hits.hits,
                ...results.responses[2].hits.hits,
              ];

              ensembles = ensembles.map((ensemble: any) => {
                return ensemble._source as EcomEnsemble;
              });

              styles = styles.map((style: any) => {
                return style._source as EcomStyle;
              });

              if (styles.length + ensembles.length == 0) {
                this.emptyResults = true;
              } else {
                this.emptyResults = false;
              }
              this.ensemblesSize = results.responses[0].hits.total.value;
              this.rentalStylesSize = results.responses[1].hits.total.value;
              this.purchaseStylesSize = results.responses[2].hits.total.value;
              this.resultSize =
                this.rentalStylesSize +
                this.ensemblesSize +
                this.purchaseStylesSize;
            }
            this.styles = styles;
            this.ensembles = ensembles;
            this.scrollToTop();
          }),
        )
        .pipe(catchError(this.handleError)),
    );
  }

  private paramsChange(params: Params, queryParams: Params): void {
    this.searchText = queryParams.searchText;
    if (!this.searchText) {
      this.ensembles = [];
      this.emptyResults = true;
      return;
    }
    if (params.category) {
      this.categoryItem = this.categories.byRoute(params.category);
    }
  }

  private async getProducts() {
    try {
      this.styles = null;
      this.filterCategories = [];

      if (this.searchText) {
        this.getProductsBySearchText();
      }
    } catch (error: any) {
      console.log(
        'An error occurred on getStyles() > listing-page.component.ts',
      );
    }
  }

  private async getProductsBySearchText() {
    await firstValueFrom(
      this.search
        .globalProductSearch(this.searchText!)
        .pipe(
          map((results: any) => {
            let styles: EcomStyle[] = [];
            let ensembles: EcomEnsemble[] = [];
            let currentPageMax = 24;
            if (results.responses.length) {
              styles = [
                ...results.responses[1].hits.hits,
                ...results.responses[2].hits.hits,
              ];

              ensembles = [...results.responses[0].hits.hits];
              ensembles = ensembles.map((ensemble: any, index: number) => {
                return ensemble._source as EcomEnsemble;
              });
              styles = styles.map((style: any, index: number) => {
                return style._source as EcomStyle;
              });

              ensembles = ensembles.filter((ensemble) => {
                currentPageMax--;
                if (currentPageMax < 0) {
                  return false;
                }
                return ensemble;
              });

              styles = styles.filter((style) => {
                currentPageMax--;
                if (currentPageMax < 0) {
                  return false;
                }
                return style;
              });

              if (styles.length + ensembles.length == 0) {
                this.emptyResults = true;
              } else {
                this.emptyResults = false;
              }

              this.ensemblesSize = results.responses[0].hits.total.value;
              this.rentalStylesSize = results.responses[1].hits.total.value;
              this.purchaseStylesSize = results.responses[2].hits.total.value;
              this.resultSize =
                this.rentalStylesSize +
                this.ensemblesSize +
                this.purchaseStylesSize;
            }
            this.styles = styles;
            this.ensembles = ensembles;
            this.paginator.firstPage();
            this.paginator.pageSize = 24;
          }),
        )
        .pipe(catchError(this.handleError)),
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log(
      'WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR SEACH-COMPONENT',
    );
    console.log('Error: ', err);
    return new Observable<never>();
  }

  public get filterSelect(): typeof FilterSelect {
    return FilterSelect;
  }

  public getImage(style: EcomStyle | EcomEnsemble): Image {
    return getImage(style);
  }

  getImageFromImageKit(object: EcomStyle | EcomEnsemble): Image {
    if (environment.imageKitPLPEnabled) {
      return getImageKitImage(object);
    } else {
      return getImage(object);
    }
  }

  public showMobileFilters(): void {
    this.navigationService.showPlpNav.next(true);
  }

  public getStyleRentPrice(style: EcomStyle): number {
    return getStyleRentPrice(style) ?? 0;
  }

  public getStyleBuyPrice(style: EcomStyle): number {
    return getStyleBuyPrice(style as EcomStyle) ?? 0;
  }

  public getEnsembleRentPrice(ensemble: EcomEnsemble): number {
    return getEnsembleRentPrice(ensemble);
  }

  public getEnsembleBuyPrice(ensemble: EcomEnsemble): number {
    return getEnsembleBuyPrice(ensemble);
  }

  public hasDisplayPrice(ecomStyle: EcomStyle): boolean {
    return hasDisplayPrice(ecomStyle);
  }

  public isPurchaseOnly(ecomStyle: EcomStyle): boolean {
    return canOnlyBuyStyle(ecomStyle);
  }

  public displayRentText(ecomStyle: EcomStyle): string {
    if (canRentAndBuyStyle(ecomStyle)) {
      return 'AVAILABLE TO RENT OR BUY';
    } else {
      if (canOnlyRentStyle(ecomStyle)) {
        return 'AVAILABLE TO RENT';
      }
    }
    return '';
  }

  private scrollToTop(): void {
    if (!this.isBrowser) return;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
}
