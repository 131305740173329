import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SEOService } from '../../services/seo/seo-service';

type SeoParams = {
  metaTitle: string;
  metaDescription: string;
  noIndex: boolean | undefined;
  metaKeywords: string | undefined;
};

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class SEOResolver implements Resolve {
  private seoService = inject(SEOService);
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { seoParams } = route.data;

    const seo = seoParams as SeoParams;

    const metaInfo = {
      metaDescription: seo?.metaDescription ?? undefined,
      metaTitle: seo?.metaTitle ?? undefined,
      metaKeywords: seo?.metaKeywords ?? undefined,
      noIndex: seo?.noIndex ?? undefined,
    };

    // Check if it is a parameterized route (we do not index paramterized routes)
    const { queryParams } = route;
    const isParameterizedRoute = Object.keys(queryParams).length > 0;
    if (isParameterizedRoute) {
      this.seoService.setNoIndexTag(true);
      /**
       * If noIndex is ever true, canonical should be removed
       * Default remove canonical.
       *  */
      this.seoService.setCanonicalTag(state.url, false);
    } else {
      this.seoService.setNoIndexTag(seo?.noIndex ?? undefined);
      this.seoService.setCanonicalTag(state.url, !metaInfo.noIndex);
    }

    /// Always set meta tags
    this.seoService.setMetaTags(metaInfo);
  }
}
