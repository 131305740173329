import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { SSR_REQUEST_RECEIVED_TIME_TOKEN } from '../../tokens/ssr-request-received-time.token';

/** This header is used to track requests that all come from a single initial request on Ecom SSR function */
const SSR_REQUEST_RECEIVED_TIME_HEADER = 'ssr-request-received-time';

const ENABLED = true;

/**
 * This interceptor sets the 'ssr-request-received-time' header on all requests made from the browser
 * using the value from the SSR_REQUEST_TIME_RECEIVED_TOKEN (which is set on the server and passed to the browser
 * via TransferState).
 */
export const ssrRequestReceivedTimeInterceptor: HttpInterceptorFn = (
  req,
  next,
) => {
  console.log('ssrRequestTimeInterceptor...');
  // console.log('req.urlWithParams: ', req.urlWithParams);
  const ssrRequestReceivedTime = inject(SSR_REQUEST_RECEIVED_TIME_TOKEN);

  if (!ENABLED) {
    console.log('ssrRequestTimeInterceptor is DISABLED.');
    return next(req)
      .pipe
      // tap((event) => console.log("http response ", event)) // add back in to see response
      ();
  }

  console.log(
    `setting ssrRequestReceivedTime header to`,
    ssrRequestReceivedTime,
  );
  const newReq = req.clone({
    setHeaders: { [SSR_REQUEST_RECEIVED_TIME_HEADER]: ssrRequestReceivedTime },
  });
  return next(newReq);
};
